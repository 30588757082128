import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Header from "../components/AccountHeader";
import MediaQuery from "react-responsive";
import useLocalStorage from "use-local-storage";
import NavBAR from "../components/Navbar";
import { logout } from "../functions/inAppFunctions";
const SettingsView = () => {
  const navigate = useNavigate();
  const gotoSignUpPage = () => navigate("/sign-up");
  const [open, setOpen] = useState(false);
  const [openMobile, setOpenMobile] = useState(false);

  const LogOut = () => navigate("/");

  const [theme, setTheme] = useLocalStorage("dark" ? "theme" : "light");

  const SwitchTheme = () => {
    const newTheme = theme === "light" ? "dark" : "light";
    setTheme(newTheme);
  };

  let ico;
  if (theme == "light") {
    ico = (
      <i onClick={SwitchTheme} className="fas fa-moon fa-lg text-white"></i>
    );
    document.body.style = "background: #d4dde2;";
  } else if (theme == "dark") {
    ico = <i onClick={SwitchTheme} className="fas fa-sun fa-lg text-white"></i>;
    document.body.style = "background: #182136;";
  }

  return (
    <div id={theme}>
      <MediaQuery minWidth={1224}>
        <NavBAR current_page={"settings"} />{" "}
        <div className="mb-3 mt-4 row w-100">
          <div className="col-3">
            <ul className="navbar-nav flex-row">
              <li className="nav-item me-3 me-lg-1">
                <Link
                  className="nav-link d-sm-flex align-items-sm-center body-header-btns1"
                  to="/add-currency"
                >
                  <h6 className="d-none d-sm-block ms-1 mt-1 d-flex">
                    <i className="fas fa-plus-circle fa-lg mr-2"></i> Add New
                    Currency
                  </h6>
                </Link>
              </li>
            </ul>
          </div>
          <div className="col-4 container">
            <h4 className="text-center text-white pt-4">Settings </h4>
            <div className="pt-2 mt-2 card-container-transparent">
              <img
                className="container-transparent-bg"
                src="/imgs/card.png"
                alt=""
              />
              <h5
                style={{
                  marginTop: "-37%",
                  marginLeft: "14%",
                  fontWeight: "bold",
                  position: "absolute",
                  color: "#333334",
                }}
              >
                <small style={{ fontWeight: "bold" }}>D€</small> 10,500.00
              </h5>
              <img
                className="container-transparent-img"
                width=""
                style={{
                  marginTop: "-50%",
                  marginLeft: "30%",
                  position: "relative",
                }}
                src="/imgs/master-card.png"
                alt=""
              />
              <p
                style={{
                  marginTop: "-27%",
                  marginLeft: "14%",
                  fontSize: "14px",
                  fontWeight: "500",
                  position: "absolute",
                  color: "#333334",
                }}
              >
                {localStorage.getItem("email")}.
              </p>
              <p
                style={{
                  marginTop: "-21%",
                  marginLeft: "14%",
                  fontSize: "14px",
                  fontWeight: "500",
                  position: "absolute",
                  color: "#333334",
                }}
              >
                **** - **** - **** - 4878
              </p>
              <p
                style={{
                  marginTop: "-16%",
                  marginLeft: "14%",
                  fontSize: "14px",
                  fontWeight: "500",
                  position: "absolute",
                  color: "#333334",
                }}
              >
                12/24
              </p>
            </div>
            <hr
              style={{ color: "white", width: "90%", backgroundColor: "white" }}
            />
            <h6 className="text-center" style={{ color: "white" }}>
              Card Settings{" "}
            </h6>
            <div className="col-12 mt-4 mb-4">
              <div className="collapse show" id="collapseExample">
                <div
                  className="mt-2 navbar-brand p-1 w-100 me-2 mb-1 align-items-center"
                  style={{ borderRadius: "15px" }}
                >
                  <span
                    style={{
                      height: "50px",
                      width: "60px",
                      boxShadow: "1px 1px 5px 3px #888888",
                      borderRadius: "10px",
                      color: "#333334FF",
                      padding: "6px 10px 0px 10px",
                      fontSize: "25px",
                      fontWeight: "bold",
                      border: "none",
                      backgroundColor: "white",
                    }}
                    className="float-left btn mr-3 btn-warning text-bold"
                  >
                    <i className="fas fa-credit-card"></i>
                  </span>
                  <span
                    className="float-left"
                    style={{
                      fontWeight: "bold",
                      color: "white",
                      fontSize: "15px",
                    }}
                  >
                    <span
                      style={{
                        fontWeight: "bold",
                        color: "white",
                        fontSize: "15px",
                      }}
                    >
                      **** - **** - **** - 4878
                    </span>
                    <br />
                    <span
                      style={{
                        fontWeight: "bold",
                        color: "#bbbbbb",
                        fontSize: "12px",
                      }}
                    >
                      Card Number
                    </span>
                  </span>
                  <span
                    className="float-right"
                    style={{
                      color: "#2EC6BA",
                      fontSize: "14px",
                      fontWeight: "bold",
                      marginTop: "3%",
                    }}
                  >
                    Show
                  </span>
                </div>
                <div
                  className="mt-2 navbar-brand p-1 w-100 me-2 mb-1 align-items-center"
                  style={{ borderRadius: "15px" }}
                >
                  <span
                    style={{
                      height: "50px",
                      width: "60px",
                      boxShadow: "1px 1px 5px 3px #888888",
                      borderRadius: "10px",
                      color: "#333334FF",
                      padding: "6px 10px 0px 10px",
                      fontSize: "25px",
                      fontWeight: "bold",
                      border: "none",
                      backgroundColor: "white",
                    }}
                    className="float-left btn mr-3 btn-warning text-bold"
                  >
                    <i
                      className="fas fa-hourglass"
                      style={{ rotate: "90deg" }}
                    ></i>
                  </span>
                  <span
                    className="float-left"
                    style={{
                      fontWeight: "bold",
                      color: "white",
                      fontSize: "15px",
                    }}
                  >
                    <span
                      style={{
                        fontWeight: "bold",
                        color: "white",
                        fontSize: "15px",
                      }}
                    >
                      Set limits
                    </span>
                    <br />
                    <span
                      style={{
                        fontWeight: "bold",
                        color: "#bbbbbb",
                        fontSize: "12px",
                      }}
                    >
                      Set a limit for spending
                    </span>
                  </span>
                  <span
                    className="float-right"
                    style={{
                      color: "#2EC6BA",
                      fontSize: "14px",
                      fontWeight: "bold",
                      marginTop: "3%",
                    }}
                  >
                    <label className="switch">
                      <input type="checkbox" />
                      <span className="slider round"></span>
                    </label>
                  </span>
                </div>
                <div
                  className="mt-2 navbar-brand p-1 w-100 me-2 mb-1 align-items-center"
                  style={{ borderRadius: "15px" }}
                >
                  <span
                    style={{
                      height: "50px",
                      width: "60px",
                      boxShadow: "1px 1px 5px 3px #888888",
                      borderRadius: "10px",
                      color: "#333334FF",
                      padding: "6px 10px 0px 10px",
                      fontSize: "25px",
                      fontWeight: "bold",
                      border: "none",
                      backgroundColor: "white",
                    }}
                    className="float-left btn mr-3 btn-warning text-bold"
                  >
                    <i className="fas fa-lock"></i>
                  </span>
                  <span
                    className="float-left"
                    style={{
                      fontWeight: "bold",
                      color: "white",
                      fontSize: "15px",
                    }}
                  >
                    <span
                      style={{
                        fontWeight: "bold",
                        color: "white",
                        fontSize: "15px",
                      }}
                    >
                      Freeze Card
                    </span>
                    <br />
                    <span
                      style={{
                        fontWeight: "bold",
                        color: "#bbbbbb",
                        fontSize: "12px",
                      }}
                    >
                      Temporary disable card usage
                    </span>
                  </span>
                  <span
                    className="float-right"
                    style={{
                      color: "#2EC6BA",
                      fontSize: "14px",
                      fontWeight: "bold",
                      marginTop: "3%",
                    }}
                  >
                    <label className="switch">
                      <input type="checkbox" />
                      <span className="slider round"></span>
                    </label>
                  </span>
                </div>
                <div
                  className="mt-2 navbar-brand p-1 w-100 me-2 mb-1 align-items-center"
                  style={{ borderRadius: "15px" }}
                >
                  <span
                    style={{
                      height: "50px",
                      width: "60px",
                      boxShadow: "1px 1px 5px 3px #888888",
                      borderRadius: "10px",
                      color: "#333334FF",
                      padding: "6px 10px 0px 10px",
                      fontSize: "25px",
                      fontWeight: "bold",
                      border: "none",
                      backgroundColor: "white",
                    }}
                    className="float-left btn mr-3 btn-warning text-bold"
                  >
                    <i className="fas fa-wifi"></i>
                  </span>
                  <span
                    className="float-left"
                    style={{
                      fontWeight: "bold",
                      color: "white",
                      fontSize: "15px",
                    }}
                  >
                    <span
                      style={{
                        fontWeight: "bold",
                        color: "white",
                        fontSize: "15px",
                      }}
                    >
                      Online Payments
                    </span>
                    <br />
                    <span
                      style={{
                        fontWeight: "bold",
                        color: "#bbbbbb",
                        fontSize: "12px",
                      }}
                    >
                      Enable online transactions
                    </span>
                  </span>
                  <span
                    className="float-right"
                    style={{
                      color: "#2EC6BA",
                      fontSize: "14px",
                      fontWeight: "bold",
                      marginTop: "3%",
                    }}
                  >
                    <label className="switch">
                      <input type="checkbox" />
                      <span className="slider round"></span>
                    </label>
                  </span>
                </div>
                <div
                  className="mt-2 navbar-brand p-1 w-100 me-2 mb-1 align-items-center"
                  style={{ borderRadius: "15px" }}
                >
                  <span
                    style={{
                      height: "50px",
                      width: "60px",
                      boxShadow: "1px 1px 5px 3px #888888",
                      borderRadius: "10px",
                      color: "#333334FF",
                      padding: "6px 10px 0px 10px",
                      fontSize: "25px",
                      fontWeight: "bold",
                      border: "none",
                      backgroundColor: "white",
                    }}
                    className="float-left btn mr-3 btn-warning text-bold"
                  >
                    <i className="fas fa-globe"></i>
                  </span>
                  <span
                    className="float-left"
                    style={{
                      fontWeight: "bold",
                      color: "white",
                      fontSize: "15px",
                    }}
                  >
                    <span
                      style={{
                        fontWeight: "bold",
                        color: "white",
                        fontSize: "15px",
                      }}
                    >
                      International Payments
                    </span>
                    <br />
                    <span
                      style={{
                        fontWeight: "bold",
                        color: "#bbbbbb",
                        fontSize: "12px",
                      }}
                    >
                      We’ll access your location to prevent fraud operations
                    </span>
                  </span>
                  <span
                    className="float-right"
                    style={{
                      color: "#2EC6BA",
                      fontSize: "14px",
                      fontWeight: "bold",
                      marginTop: "3%",
                    }}
                  >
                    <label className="switch">
                      <input type="checkbox" />
                      <span className="slider round"></span>
                    </label>
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="col-3 align-center">
            <ul className="navbar-nav flex-row">
              <li className="nav-item me-3 me-lg-1">
                <a
                  className="nav-link d-sm-flex align-items-sm-center body-header-btns1"
                  href="#"
                >
                  <h6 className="d-none d-sm-block ms-1 mt-1 d-flex">
                    <i className="fas fa-commenting fa-lg mr-2"></i> Need Help?
                  </h6>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </MediaQuery>

      <MediaQuery maxWidth={1224}>
        <div
          className="navbar-toggler d-flex justify-content-between align-items-center carib-head"
          style={{ position: "fixed", zIndex: "3", top: "0", width: "100%" }}
        >
          <Link className="navbar-brand" to="/wallet">
            <img
              src="/carib-coin-logo.png"
              height="40px"
              alt="Caribbean-coin"
              loading="lazy"
              style={{ marginTop: "2px" }}
            />
          </Link>
          <div className="navbar-brand ">
            <span
              className=""
              style={{ fontWeight: "bold", color: "white", fontSize: "16px" }}
            >
              Settings
            </span>
          </div>
          <div className="navbar-brand">
            <span
              style={{
                height: "40px",
                borderRadius: "100%",
                padding: "5px 3px 0px 3px",
                fontSize: "20px",
                border: "none",
              }}
              className="w-100 text-bold carib-text-color"
            >
              {ico}
            </span>
            <span
              className="text-white"
              onClick={() => {
                setOpenMobile(!open);
              }}
            >
              <i className="fas fa-ellipsis-h ml-3 fa-lg text-white"></i>
            </span>
          </div>
        </div>
        <div className={`mobile-menu ${openMobile ? "active" : "inactive"}`}>
          <div className="navbar-toggler d-flex pt-4">
            <Link
              className="navbar-brand me-2 mb-1 d-flex align-items-center"
              to="/wallet"
            >
              <img
                src="/carib-coin-logo.png"
                height="40px"
                alt="Caribbean-coin"
                loading="lazy"
                style={{ marginTop: "2px" }}
              />
              <span
                className="ml-2"
                style={{
                  fontWeight: "bold",
                  color: "#f4e081",
                  fontSize: "24px",
                }}
              >
                CARIBBEAN-COIN
              </span>
            </Link>
            <i
              className="fas fa-times fa-lg"
              onClick={() => {
                setOpenMobile(open);
              }}
              style={{ margin: "27px 0px 0px 0px", color: "#f4e081" }}
            ></i>
          </div>
          <ul className="navbar-nav p-3 text-white">
            <li className="">
              <span className="align-items-sm-center">
                <h6 className="ml-2 d-flex">{localStorage.getItem("email")}</h6>
                <span className="verify-btn ml-2">
                  <i className="fas fa-exclamation-circle mr-1"></i> Not Verify
                </span>
              </span>
            </li>
          </ul>
          <ul className="navbar-nav p-4 text-white">
            <li className="menu-nav-item">
              <Link
                className="nav-link d-sm-flex align-items-sm-center"
                to="/my-profile"
              >
                <h6 className="d-none d-sm-block ms-1 mt-1 d-flex">
                  <i className="fas fa-user fa-lg mt-2 mr-3"></i> My Profile
                </h6>
              </Link>
            </li>
            <li className="menu-nav-item">
              <Link
                className="nav-link d-sm-flex align-items-sm-center"
                to="/invest"
              >
                <h6 className="d-none d-sm-block ms-1 mt-1 d-flex">
                  <i
                    className="fas fa-exchange fa-lg mt-2 mr-3"
                    style={{ rotate: "90deg" }}
                  ></i>{" "}
                  Swap
                </h6>
              </Link>
            </li>
            <li className="menu-nav-item">
              <Link className="nav-link d-sm-flex align-items-sm-center">
                <h6 className="d-none d-sm-block ms-1 mt-1 d-flex">
                  <i className="fas fa-commenting fa-lg mt-2 mr-3"></i> Need
                  help
                </h6>
              </Link>
            </li>
            <li className="menu-nav-item">
              <a
                className="nav-link d-sm-flex align-items-sm-center"
                onClick={() => logout(navigate)}
              >
                <h6 className="d-none d-sm-block ms-1 mt-1 d-flex">
                  <i className="fas fa-sign-out fa-lg mt-2 mr-3"></i> Sign out
                </h6>
              </a>
            </li>
          </ul>
        </div>
        <div className="signup__container w-100 pt-5">
          <div
            className="container-mob w-100 pb-4"
            style={{ zIndex: "0px", width: "100%", height: "850px" }}
          >
            <div className="pt-4 card-container-transparent">
              <img
                className="container-transparent-bg"
                src="/imgs/card.png"
                alt=""
              />
              <h5
                style={{
                  marginTop: "-45%",
                  marginLeft: "10%",
                  fontWeight: "bold",
                  position: "absolute",
                  color: "#333334",
                }}
              >
                <small style={{ fontWeight: "bold" }}>D€</small> 10,500.00
              </h5>
              <img
                className="container-transparent-img"
                width=""
                style={{
                  marginTop: "-57%",
                  marginLeft: "80%",
                  position: "relative",
                }}
                src="/imgs/master-card.png"
                alt=""
              />
              <p
                style={{
                  marginTop: "-29%",
                  marginLeft: "10%",
                  fontSize: "13px",
                  fontWeight: "500",
                  position: "absolute",
                  color: "#333334",
                }}
              >
                {localStorage.getItem("email")}.
              </p>
              <p
                style={{
                  marginTop: "-23%",
                  marginLeft: "10%",
                  fontSize: "13px",
                  fontWeight: "500",
                  position: "absolute",
                  color: "#333334",
                }}
              >
                **** - **** - **** - 4878
              </p>
              <p
                style={{
                  marginTop: "-18%",
                  marginLeft: "10%",
                  fontSize: "13px",
                  fontWeight: "500",
                  position: "absolute",
                  color: "#333334",
                }}
              >
                12/24
              </p>
            </div>
            <hr
              style={{ color: "white", width: "90%", backgroundColor: "white" }}
            />
            <h6 className="text-center" style={{ color: "white" }}>
              Card Settings{" "}
            </h6>
            <div className="col-12 mt-4 mb-4">
              <div className="collapse show" id="collapseExample">
                <div
                  className="mt-2 navbar-brand p-1 w-100 me-2 mb-1 align-items-center"
                  style={{ borderRadius: "15px" }}
                >
                  <span
                    style={{
                      height: "45px",
                      width: "55px",
                      boxShadow: "1px 1px 5px 3px #888888",
                      borderRadius: "10px",
                      color: "#333334FF",
                      padding: "5px 10px 0px 10px",
                      fontSize: "23px",
                      fontWeight: "bold",
                      border: "none",
                      backgroundColor: "white",
                    }}
                    className="float-left btn mr-3 btn-warning text-bold"
                  >
                    <i className="fas fa-credit-card"></i>
                  </span>
                  <span
                    className="float-left"
                    style={{
                      fontWeight: "bold",
                      color: "white",
                      fontSize: "15px",
                    }}
                  >
                    <span
                      style={{
                        fontWeight: "bold",
                        color: "white",
                        fontSize: "13px",
                      }}
                    >
                      **** - **** - **** - 4878
                    </span>
                    <br />
                    <span
                      style={{
                        fontWeight: "bold",
                        color: "#868686",
                        fontSize: "9px",
                      }}
                    >
                      Card Number
                    </span>
                  </span>
                  <span
                    className="float-right"
                    style={{
                      color: "#2EC6BA",
                      fontSize: "12px",
                      fontWeight: "bold",
                      marginTop: "3%",
                    }}
                  >
                    Show
                  </span>
                </div>
                <div
                  className="mt-2 navbar-brand p-1 w-100 me-2 mb-1 align-items-center"
                  style={{ borderRadius: "15px" }}
                >
                  <span
                    style={{
                      height: "45px",
                      width: "55px",
                      boxShadow: "1px 1px 5px 3px #888888",
                      borderRadius: "10px",
                      color: "#333334FF",
                      padding: "5px 10px 0px 10px",
                      fontSize: "23px",
                      fontWeight: "bold",
                      border: "none",
                      backgroundColor: "white",
                    }}
                    className="float-left btn mr-3 btn-warning text-bold"
                  >
                    <i
                      className="fas fa-hourglass"
                      style={{ rotate: "90deg" }}
                    ></i>
                  </span>
                  <span
                    className="float-left"
                    style={{
                      fontWeight: "bold",
                      color: "white",
                      fontSize: "15px",
                    }}
                  >
                    <span
                      style={{
                        fontWeight: "bold",
                        color: "white",
                        fontSize: "13px",
                      }}
                    >
                      Set limits
                    </span>
                    <br />
                    <span
                      style={{
                        fontWeight: "bold",
                        color: "#868686",
                        fontSize: "9px",
                      }}
                    >
                      Set a limit for spending
                    </span>
                  </span>
                  <span
                    className="float-right"
                    style={{
                      color: "#2EC6BA",
                      fontSize: "12px",
                      fontWeight: "bold",
                      marginTop: "3%",
                    }}
                  >
                    <label className="switch">
                      <input type="checkbox" />
                      <span className="slider round"></span>
                    </label>
                  </span>
                </div>
                <div
                  className="mt-2 navbar-brand p-1 w-100 me-2 mb-1 align-items-center"
                  style={{ borderRadius: "15px" }}
                >
                  <span
                    style={{
                      height: "45px",
                      width: "55px",
                      boxShadow: "1px 1px 5px 3px #888888",
                      borderRadius: "10px",
                      color: "#333334FF",
                      padding: "5px 10px 0px 10px",
                      fontSize: "23px",
                      fontWeight: "bold",
                      border: "none",
                      backgroundColor: "white",
                    }}
                    className="float-left btn mr-3 btn-warning text-bold"
                  >
                    <i className="fas fa-lock"></i>
                  </span>
                  <span
                    className="float-left"
                    style={{
                      fontWeight: "bold",
                      color: "white",
                      fontSize: "15px",
                    }}
                  >
                    <span
                      style={{
                        fontWeight: "bold",
                        color: "white",
                        fontSize: "13px",
                      }}
                    >
                      Freeze Card
                    </span>
                    <br />
                    <span
                      style={{
                        fontWeight: "bold",
                        color: "#868686",
                        fontSize: "9px",
                      }}
                    >
                      Temporary disable card usage
                    </span>
                  </span>
                  <span
                    className="float-right"
                    style={{
                      color: "#2EC6BA",
                      fontSize: "12px",
                      fontWeight: "bold",
                      marginTop: "3%",
                    }}
                  >
                    <label className="switch">
                      <input type="checkbox" />
                      <span className="slider round"></span>
                    </label>
                  </span>
                </div>
                <div
                  className="mt-2 navbar-brand p-1 w-100 me-2 mb-1 align-items-center"
                  style={{ borderRadius: "15px" }}
                >
                  <span
                    style={{
                      height: "45px",
                      width: "55px",
                      boxShadow: "1px 1px 5px 3px #888888",
                      borderRadius: "10px",
                      color: "#333334FF",
                      padding: "5px 10px 0px 10px",
                      fontSize: "23px",
                      fontWeight: "bold",
                      border: "none",
                      backgroundColor: "white",
                    }}
                    className="float-left btn mr-3 btn-warning text-bold"
                  >
                    <i className="fas fa-wifi"></i>
                  </span>
                  <span
                    className="float-left"
                    style={{
                      fontWeight: "bold",
                      color: "white",
                      fontSize: "15px",
                    }}
                  >
                    <span
                      style={{
                        fontWeight: "bold",
                        color: "white",
                        fontSize: "13px",
                      }}
                    >
                      Online Payments
                    </span>
                    <br />
                    <span
                      style={{
                        fontWeight: "bold",
                        color: "#868686",
                        fontSize: "9px",
                      }}
                    >
                      Enable online transactions
                    </span>
                  </span>
                  <span
                    className="float-right"
                    style={{
                      color: "#2EC6BA",
                      fontSize: "12px",
                      fontWeight: "bold",
                      marginTop: "3%",
                    }}
                  >
                    <label className="switch">
                      <input type="checkbox" />
                      <span className="slider round"></span>
                    </label>
                  </span>
                </div>
                <div
                  className="mt-2 navbar-brand p-1 w-100 me-2 mb-1 align-items-center"
                  style={{ borderRadius: "15px" }}
                >
                  <span
                    style={{
                      height: "45px",
                      width: "55px",
                      boxShadow: "1px 1px 5px 3px #888888",
                      borderRadius: "10px",
                      color: "#333334FF",
                      padding: "5px 10px 0px 10px",
                      fontSize: "23px",
                      fontWeight: "bold",
                      border: "none",
                      backgroundColor: "white",
                    }}
                    className="float-left btn mr-3 btn-warning text-bold"
                  >
                    <i className="fas fa-globe"></i>
                  </span>
                  <span
                    className="float-left"
                    style={{
                      fontWeight: "bold",
                      color: "white",
                      fontSize: "15px",
                    }}
                  >
                    <span
                      style={{
                        fontWeight: "bold",
                        color: "white",
                        fontSize: "13px",
                      }}
                    >
                      International Payments
                    </span>
                    <br />
                    <span
                      style={{
                        fontWeight: "bold",
                        color: "#868686",
                        fontSize: "8px",
                      }}
                    >
                      We’ll access your location to prevent fraud operations
                    </span>
                  </span>
                  <span
                    className="float-right"
                    style={{
                      color: "#2EC6BA",
                      fontSize: "12px",
                      fontWeight: "bold",
                      marginTop: "3%",
                    }}
                  >
                    <label className="switch">
                      <input type="checkbox" />
                      <span className="slider round"></span>
                    </label>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <footer
          className="carib-head"
          style={{
            zIndex: "3",
            bottom: "0px",
            position: "fixed",
            width: "100%",
            height: "100px",
          }}
        >
          <div className="navbar-toggler d-flex justify-content-between align-items-center">
            <Link
              className="navbar-brand mob-footer-nav text-center carib-text-color2"
              to="/wallet"
            >
              <span style={{ fontSize: "25px", border: "none" }} className="">
                <i className="fas fa-wallet"></i>
              </span>
              <span className="d-flex mt-2 align-items-center">Wallet</span>
            </Link>
            <Link
              className="navbar-brand mob-footer-nav text-center carib-text-color2"
              to="/my-savings"
            >
              <span style={{ fontSize: "25px", border: "none" }} className="">
                <i className="fas fa-sack-dollar"></i>
              </span>
              <span className="d-flex mt-2 align-items-center">Savings</span>
            </Link>
            <Link
              className="navbar-brand mob-footer-nav text-center carib-text-color2"
              to="/invest"
            >
              <span style={{ fontSize: "25px", border: "none" }} className="">
                <i className="fas fa-chart-line"></i>
              </span>
              <span className="d-flex mt-2 align-items-center">Invest</span>
            </Link>
            <Link
              className="navbar-brand mob-footer-nav active text-center"
              to=""
            >
              <span style={{ fontSize: "25px", border: "none" }} className="">
                <i className="fas fa-gear"></i>
              </span>
              <span className="d-flex mt-2 align-items-center">Settings</span>
            </Link>
          </div>
        </footer>
      </MediaQuery>
    </div>
  );
};

export default SettingsView;
