import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
function Header(activeNav) {
  const navigate = useNavigate();
  const LogOut = () => navigate("/");

  // const [isWallet, setisWallet] = useState(false);
  // const [isSavings, setisSavings] = useState(false);
  //
  // if(activeNav === 'wallet'){
  //     console.log("wal");
  // } else if(activeNav == 'savings'){
  //     console.log("sav");
  // }

  const [open, setOpen] = useState(false);

  return (
    <nav
      className="navbar navbar-expand-lg navbar-light"
      style={{ backgroundColor: "#2c2b2b" }}
    >
      <div className="navbar-toggler">
        <Link
          className="navbar-brand me-2 mb-1 d-flex align-items-center"
          to="/wallet"
        >
          <img
            src="/carib-coin-logo.png"
            height="50px"
            alt="Caribbean-coin"
            loading="lazy"
            style={{ marginTop: "2px" }}
          />
          <span
            className=""
            style={{ fontWeight: "bold", color: "#f4e081", fontSize: "26px" }}
          >
            CARIBBEAN
          </span>
        </Link>
      </div>

      <button
        className="navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span className="navbar-toggler-icon"></span>
      </button>

      <div
        className="collapse navbar-collapse justify-content-between"
        id="navbarSupportedContent"
      >
        {/*<div className="container-fluid justify-content-between">*/}
        <ul className="navbar-nav flex-row">
          <Link
            className="navbar-brand me-2 mb-1 d-flex align-items-center"
            to="/wallet"
          >
            <img
              src="/carib-coin-logo.png"
              height="50px"
              alt="Caribbean-coin"
              loading="lazy"
              style={{ marginTop: "2px" }}
            />
            <span
              className=""
              style={{ fontWeight: "bold", color: "#f4e081", fontSize: "26px" }}
            >
              CARIBBEAN
            </span>
          </Link>
        </ul>

        <ul className="navbar-nav flex-row d-none d-md-flex">
          <li className="nav-item nav-item-link me-3 me-lg-1 p-2">
            <Link
              className="nav-link d-sm-flex  align-items-sm-center"
              activeClassName="active"
              to="/wallet"
            >
              <span className="navs d-none d-sm-block ms-1 mt-1 d-flex">
                <i className="fas fa-wallet fa-lg mr-2"></i> Wallet
              </span>
            </Link>
          </li>
          <li className="nav-item nav-item-link me-3 me-lg-1 p-2">
            <Link
              className="nav-link d-sm-flex  align-items-sm-center"
              activeClassName="active"
              to="/my-savings"
            >
              <span className="navs d-none d-sm-block ms-1 mt-1 d-flex">
                <i className="fas fa-sack-dollar fa-lg mr-2"></i> Savings
              </span>
            </Link>
          </li>
          <li className="nav-item nav-item-link me-3 me-lg-1 p-2">
            <Link className="nav-link d-sm-flex align-items-sm-center" to="#">
              <span className="navs d-none d-sm-block ms-1 mt-1 d-flex">
                <i className="fas fa-chart-line fa-lg mr-2"></i> Invest
              </span>
            </Link>
          </li>
          <li className="nav-item nav-item-link me-3 me-lg-1 p-2">
            <Link className="nav-link d-sm-flex align-items-sm-center" to="#">
              <span className="navs d-none d-sm-block ms-1 mt-1 d-flex">
                <i className="fas fa-gear fa-lg mr-2"></i> Settings
              </span>
            </Link>
          </li>
        </ul>

        <ul className="navbar-nav flex-row">
          <li className="nav-item me-3 me-lg-1">
            <a
              className="nav-link d-sm-flex align-items-sm-center header-right-btns1"
              href="#"
            >
              <h6 className="d-none d-sm-block ms-1 mt-1 d-flex">
                <i className="fas fa-sun fa-lg"></i>
              </h6>
            </a>
          </li>
          <li className="nav-item me-3 me-lg-1">
            <a
              className="nav-link d-sm-flex align-items-sm-center header-right-btns2"
              href="#"
            >
              <h6 className="d-none d-sm-block ms-1 mt-1 d-flex">
                <i className="fas fa-wallet fa-lg mr-2"></i> Connect Wallet
              </h6>
            </a>
          </li>
          <li
            className="nav-item"
            onClick={() => {
              setOpen(!open);
            }}
          >
            <a className="nav-link align-items-sm-center header-right-btns3">
              <img
                src="/imgs/user.jpg"
                height="40px"
                style={{ borderRadius: "20px" }}
              />
            </a>
            <div className={`dropdown-menuu ${open ? "active" : "inactive"}`}>
              <li>
                <a className="dropdown-item">
                  <div className="user-drop-info">
                    <div className="d-flex">
                      <div className="flex-shrink-0 me-3">
                        <div className="avatar avatar-online">
                          <img
                            src="/imgs/user.jpg"
                            alt
                            height="35px"
                            style={{ borderRadius: "20px" }}
                            className="w-px-40 h-auto rounded-circle"
                          />
                        </div>
                      </div>
                      <div className="flex-grow-1">
                        <span className="fw-bolder d-block ml-2">
                          {localStorage.getItem("email")}
                        </span>
                        <br />
                      </div>
                    </div>
                    <div className="user-acc-info row mt-3">
                      <div className="col-1">
                        <i className="fas fa-exclamation-circle fa-lg"></i>
                      </div>
                      <div className="col-10">
                        <p className="user-acc-info pb-2">
                          Verify your identity to access more of <br /> what
                          Caribbean has to offer
                        </p>
                      </div>
                    </div>
                    <div className="float-right">
                      <span className="user-drop-link1 ">Hide</span>
                      <span className="user-drop-link2 ml-2">Verify ID</span>
                    </div>
                  </div>
                </a>
              </li>
              <li>
                <Link
                  onClick={LogOut}
                  className="dropdown-item text-white justify-content-center"
                  to="/"
                >
                  <i className="fas fa-right-from-bracket mr-2"></i>
                  <span className="">Log Out</span>
                </Link>
              </li>
            </div>
          </li>
        </ul>
        {/*</div>*/}
      </div>
    </nav>
  );
}
export default Header;
