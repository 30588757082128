import React from "react";
import { RiAdminFill } from "react-icons/ri";
import { Link } from "react-router-dom";
import "../style.css";

const Footer = ({ currentPage }) => {
  return (
    <footer
      className="carib-head"
      style={{
        backgroundColor: currentPage === "adminpanel" ? "#8961C4" : "",
        zIndex: "3",
        bottom: "0px",
        position: "fixed",
        width: "100%",
        height: "100px",
      }}
    >
      <div className="navbar-toggler d-flex justify-content-between align-items-center">
        <Link
          className={
            currentPage === "wallet"
              ? "navbar-brand mob-footer-nav active text-center"
              : "navbar-brand mob-footer-nav text-center carib-text-color2"
          }
          to="/wallet"
        >
          <span style={{ fontSize: "25px", border: "none" }} className="">
            <i className="fas fa-wallet"></i>
          </span>
          <span className="d-flex mt-2 align-items-center">Wallet</span>
        </Link>
        <Link
          className={
            currentPage === "savings"
              ? "navbar-brand mob-footer-nav active text-center"
              : "navbar-brand mob-footer-nav text-center carib-text-color2"
          }
          to="/my-savings"
        >
          <span style={{ fontSize: "25px", border: "none" }} className="">
            <i className="fas fa-sack-dollar"></i>
          </span>
          <span className="d-flex mt-2 align-items-center">Savings</span>
        </Link>
        <Link
          className={
            currentPage === "invest"
              ? "navbar-brand mob-footer-nav active text-center"
              : "navbar-brand mob-footer-nav text-center carib-text-color2"
          }
          to="/invest"
        >
          <span style={{ fontSize: "25px", border: "none" }} className="">
            <i className="fas fa-chart-line"></i>
          </span>
          <span className="d-flex mt-2 align-items-center">Invest</span>
        </Link>
        <Link
          className={
            currentPage === "settings"
              ? "navbar-brand mob-footer-nav active text-center"
              : "navbar-brand mob-footer-nav text-center carib-text-color2"
          }
          to="/settings"
        >
          <span style={{ fontSize: "25px", border: "none" }} className="">
            <i className="fas fa-gear"></i>
          </span>
          <span className="d-flex mt-2 align-items-center"> Settings</span>
        </Link>
      </div>
    </footer>
  );
};

export default Footer;
