import React from "react";
import { NavLink } from "react-router-dom";

const PageNotFound = () => {
  return (
    <div className="layout">
      <div className="notfound">
        <h1>404</h1>
        <h2>Page Not Found</h2>
        <p>The page you are looking for does not exist.</p>
        <NavLink to="/" className="backLink">
          Go back to homepage
        </NavLink>
      </div>
    </div>
  );
};

export default PageNotFound;
