import React, { useEffect, useState } from "react";
import BASE_URI from "../../core";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import PageNotFound from "../PageNotFound";
import styles from "./pass.module.css";
import ReactPasswordChecklist from "react-password-checklist";

const NewPass = () => {
  const [validUrl, setValidUrl] = useState(true);
  const [validate, setValidate] = useState(false);
  const [password, setPassword] = useState("");
  const [confirm, setConfirm] = useState("");
  const param = useParams();
  const navigate = useNavigate();
  const url = `${BASE_URI}password-reset/${param.id}/${param.token}`;

  useEffect(() => {
    const verifyUrl = async () => {
      try {
        await axios.get(url);
        setValidUrl(true);
      } catch (error) {
        setValidUrl(false);
      }
    };
    verifyUrl();
  }, [param, url]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (validate) {
      try {
        const { data } = await axios.post(url, { password });
        toast.success(data.message);
        navigate("/");
      } catch (error) {
        if (
          error.response &&
          error.response.status >= 400 &&
          error.response.status <= 500
        ) {
          toast.error(error.response.data.message);
        }
      }
    } else {
      toast.warning("Please fullfill all password requirements");
    }
  };

  return (
    <>
      {validUrl ? (
        <div className={styles.container}>
          <form className={styles.form_container} onSubmit={handleSubmit}>
            <h3>Add New Password</h3>
            <input
              type="password"
              placeholder="Password"
              name="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
              className={styles.input}
            />
            <input
              type="password"
              placeholder="Confirm Password"
              name="password"
              value={confirm}
              onChange={(e) => setConfirm(e.target.value)}
              required
              className={styles.input}
            />
            <ReactPasswordChecklist
              className={styles.passValidator}
              rules={["minLength", "specialChar", "number", "capital", "match"]}
              minLength={8}
              value={password}
              valueAgain={confirm}
              onChange={(isValid) => {
                setValidate(isValid);
              }}
            />
            <button type="submit" className={styles.btn}>
              Submit
            </button>
          </form>
        </div>
      ) : (
        <PageNotFound />
      )}
    </>
  );
};

export default NewPass;
