import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Header from "../components/header";
import useLocalStorage from "use-local-storage";
import BASE_URI from "../core";
import axios from "axios";
import { toast } from "react-toastify";
import Loader from "../components/Loader";

const ForgotPassword = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);

  const gotoSignInPage = () => navigate("/");
  const [theme] = useLocalStorage("theme");

  let ico;
  if (theme == "light") {
    document.body.style = "background: #d4dde2;";
  } else if (theme == "dark") {
    document.body.style = "background: #182136;";
  }

  const resetPass = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const url = `${BASE_URI}password-reset`;
      const { data } = await axios.post(url, { email });
      localStorage.setItem("reset", "pass");
      setLoading(false);
      navigate("/email-send");
    } catch (error) {
      if (
        error.response &&
        error.response.status >= 400 &&
        error.response.status <= 500
      ) {
        setLoading(false);
        toast.error(error.response.data.message);
      }
    }
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <div id={theme}>
      <Header />
      <div className="signup__container justify-content-center">
        <div className="container">
          <h4 className="text-center text-white p-4">Forgot Password? </h4>
          <h6 className="text-center" style={{ color: "#919293" }}>
            We’ll send you a link to reset the password by email{" "}
          </h6>
          <form
            className="p-3 g-3 needs-validation"
            onSubmit={(e) => resetPass(e)}
          >
            <div className="col-md-12">
              <div className="form-outline inner-addon left-addon">
                <label htmlFor="email" className="text-success">
                  email
                </label>
                <input
                  type="email"
                  style={{
                    height: "60px",
                    border: "2px solid darkolivegreen",
                    borderRadius: "12px",
                  }}
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className="form-control"
                  id="validationCustom01"
                  placeholder="E-mail"
                  required
                />
                <label
                  htmlFor="validationCustom01"
                  className="form-label"
                ></label>
                <div className="valid-feedback"></div>
              </div>
            </div>
            <div className="col-md-12 pt-1 pb-5">
              <div className="form-outline inner-addon left-addon">
                <button
                  type="submit"
                  style={{
                    height: "60px",
                    borderRadius: "20px",
                    color: "#333334",
                    fontSize: "20px",
                    fontWeight: "bold",
                    padding: "10px 40px 10px 40px",
                    border: "none",
                    backgroundColor: "#F5E494FF",
                  }}
                  className="btn btn-warning w-100 text-bold"
                >
                  Reset Password
                </button>
              </div>
            </div>
            <div className="col-md-12 pt-2 pb-3">
              <div className="form-outline inner-addon left-addon">
                <h6
                  className="text-center form-footer-text"
                  style={{ color: "#919293" }}
                >
                  <span
                    className="text-success font-weight-bold"
                    onClick={gotoSignInPage}
                  >
                    Login
                  </span>
                </h6>
              </div>
            </div>
          </form>
        </div>
      </div>
      <div className="pt-3 pb-5 mt-5 container-transparent">
        <img
          className="container-transparent-bg"
          src="/imgs/sign-up-container-bg.png"
          alt=""
        />
        <h5 className="container-transparent-text1">Convert</h5>
        <p className="container-transparent-text2">
          Convert between digital currencies with no registration.
        </p>
        <img
          className="container-transparent-img"
          width="65px"
          style={{
            marginTop: "-10px",
            marginLeft: "400px",
            position: "absolute",
          }}
          src="/carib-coin-logo.png"
          alt=""
        />
      </div>
    </div>
  );
};

export default ForgotPassword;
