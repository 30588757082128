import React from 'react';
import {Link} from "react-router-dom";
function Header() {
    return(
        <nav className="navbar navbar-expand-lg navbar-light bg-transfarent mb-5 carib-head">

            <div className="navbar-toggler">
                <Link className="navbar-brand me-2 mb-1 d-flex align-items-center" to="/wallet">
                    <img
                        src="/carib-coin-logo.png"
                        height="50px"
                        alt="Caribbean-coin"
                        loading="lazy"
                        style={{ marginTop: '2px' }}
                    />
                    <span className=""
                          style={{ fontWeight: 'bold', color: '#f4e081', fontSize: '26px' }}>CARIBBEAN-COIN</span>
                </Link>
            </div>

            <button className="navbar-toggler" type="button" data-toggle="collapse"
                    data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false"
                    aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"></span>
            </button>

            <div className="collapse navbar-collapse justify-content-between" id="navbarSupportedContent">
                {/*<div className="container-fluid justify-content-between">*/}
                {/*    <ul className="navbar-nav flex-row">*/}
                {/*        <li className="nav-item me-3 me-lg-1">*/}
                {/*            <a*/}
                {/*                className="nav-link d-sm-flex align-items-sm-center header-btns"*/}
                {/*                href="#"*/}
                {/*            >*/}
                {/*                <h6 className="d-none d-sm-block ms-1 d-flex"><i className="fas fa-wallet fa-lg mt-2 mr-2"></i> Connect Wallet</h6>*/}
                {/*            </a>*/}
                {/*        </li>*/}
                {/*    </ul>*/}

                    <ul className="navbar-nav flex-row d-none d-md-flex">
                        <li className="nav-item me-3 me-lg-1 active">
                            <Link className="navbar-brand me-2 mt-2 d-flex align-items-center" to="/wallet">
                                <img
                                    src="/carib-coin-logo.png"
                                    height="50px"
                                    alt="Caribbean-coin"
                                    style={{ marginTop: '2px', marginRight: '20px' }}
                                />
                                <span className=""
                                      style={{ fontWeight: 'bold', color: '#f4e081', fontSize: '28px' }}>CARIBBEAN-COIN</span>
                            </Link>
                        </li>
                    </ul>

                    <ul className="navbar-nav flex-row">
                        <li className="nav-item">
                            <a
                                className="nav-link d-sm-flex align-items-sm-center header-btns"
                                href="#"
                            >
                                <h6 className="d-none d-sm-block ms-1 mt-1 d-flex"><i className="fas fa-commenting fa-lg mt-2 mr-2"></i> Need Help?</h6>
                            </a>
                        </li>
                    </ul>
                {/*</div>*/}
            </div>
        </nav>
    )
}
export default Header;
