import React from "react";
import loader from "../assets/loader.gif";

const Loader = ({ width, height, radius }) => {
  return (
    <div
      style={{
        width: "100%",
        heigth: "100vh",
        position: "absolute",
        borderRadius: radius !== undefined ? radius : 0,
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "#d4dde2",
      }}
    >
      <img
        src={loader}
        alt="loader"
        width={width !== undefined ? width : "100px"}
        height={height !== undefined ? height : "100px"}
      />
    </div>
  );
};

export default Loader;
