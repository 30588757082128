import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Header from "../components/header";
import useLocalStorage from "use-local-storage";
import Loader from "../components/Loader";
import axios from "axios";
import BASE_URI from "../core";
import { toast } from "react-toastify";

const SignIn = ({ isLoggedIn, setIsLoggedIn }) => {
  const [theme] = useLocalStorage("theme");
  const [loading, setLoading] = useState(false);
  const [wallet, setWallet] = useState(true);
  const [credentials, setCredentials] = useState({
    email: "",
    password: "",
  });
  const navigate = useNavigate();

  const gotoSignUpPage = () => navigate("/sign-up");
  const gotoWalletPage = () => navigate("/wallet");

  useEffect(() => {
    let token = localStorage.getItem("token");

    if (token !== undefined && token !== null && token !== "") {
      if (isLoggedIn) {
        gotoWalletPage();
      }
    } else {
      setWallet(false);
    }
  }, [isLoggedIn]);

  let ico;
  if (theme == "light") {
    document.body.style = "background: #d4dde2;";
  } else if (theme == "dark") {
    document.body.style = "background: #182136;";
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const response = await axios.post(`${BASE_URI}login`, credentials);
      setLoading(false);

      if (response.data.message === "success") {
        toast.success("Login Successfully");
        localStorage.setItem("token", response.data.token);
        localStorage.setItem("pass", credentials.password);
        localStorage.setItem("email", credentials.email);
        setIsLoggedIn(true);
        navigate("/wallet");
      } else if (response.data.message === "fail") {
        toast.error("Sorry your acc is disabled contact support for more info");
      } else if (response.data.message === "Email Not Verified") {
        alert("An Email is send to your acc please verify");
      }
    } catch (error) {
      setLoading(false);
      toast.error(error.response.data.message);
    }

    setCredentials({
      email: "",
      password: "",
    });
  };

  return (
    <div id={theme}>
      <Header />
      {wallet ? (
        <Loader />
      ) : (
        <>
          <div className="signup__container justify-content-center">
            <div className="container">
              <h4 className="text-center text-white p-4">Login </h4>
              <h6 className="text-center" style={{ color: "#919293" }}>
                Welcome back to your account!{" "}
              </h6>
              <form
                className="p-3 g-3 needs-validation"
                onSubmit={(e) => handleSubmit(e)}
              >
                <div className="col-md-12">
                  <div className="form-outline inner-addon left-addon">
                    <label htmlFor="email" className="text-success">
                      email
                    </label>
                    <input
                      type="email"
                      style={{
                        height: "60px",
                        border: "2px solid darkolivegreen",
                        borderRadius: "12px",
                      }}
                      value={credentials.email}
                      onChange={(e) =>
                        setCredentials({
                          ...credentials,
                          email: e.target.value,
                        })
                      }
                      className="form-control"
                      id="validationCustom01"
                      placeholder="E-mail"
                      required
                    />
                    <label
                      htmlFor="validationCustom01"
                      className="form-label"
                    ></label>
                    <div className="valid-feedback"></div>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="form-outline inner-addon left-addon">
                    <input
                      type="password"
                      style={{
                        height: "60px",
                        border: "2px solid darkolivegreen",
                        borderRadius: "12px",
                      }}
                      value={credentials.password}
                      onChange={(e) =>
                        setCredentials({
                          ...credentials,
                          password: e.target.value,
                        })
                      }
                      className="form-control"
                      id="validationCustom01"
                      placeholder="Password"
                      required
                    />
                    <label
                      htmlFor="validationCustom01"
                      className="form-label"
                    ></label>
                    <div className="valid-feedback"></div>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="d-flex justify-content-between">
                    <label className="check-container text-white">
                      {" "}
                      Save password
                      <input type="checkbox" />
                      <span className="checkmark"></span>
                    </label>
                    <Link
                      to={"/forgot-password"}
                      className="flex-row text-success text-underline"
                    >
                      Forgot password?
                    </Link>
                  </div>
                </div>
                <div className="col-md-12 pt-5 pb-5">
                  <div className="form-outline inner-addon left-addon">
                    <button
                      type="submit"
                      style={{
                        height: "60px",
                        borderRadius: "20px",
                        color: "#333334",
                        fontSize: "20px",
                        fontWeight: "bold",
                        padding: "10px 40px 10px 40px",
                        border: "none",
                        backgroundColor: "#F5E494FF",
                      }}
                      className="btn btn-warning w-100 text-bold"
                    >
                      {loading ? (
                        <Loader width="50px" height="50px" radius="10px" />
                      ) : (
                        "Login"
                      )}
                    </button>
                  </div>
                </div>
                <div className="col-md-12 pt-2 pb-3">
                  <div className="form-outline inner-addon left-addon">
                    <h6
                      className="text-center form-footer-text"
                      style={{ color: "#919293" }}
                    >
                      Don't you have an account?{" "}
                      <span
                        className="text-success font-weight-bold"
                        onClick={gotoSignUpPage}
                      >
                        Join now
                      </span>
                    </h6>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <div className="pt-3 pb-5 mt-5 container-transparent">
            <img
              className="container-transparent-bg"
              src="/imgs/sign-up-container-bg.png"
              alt=""
            />
            <h5 className="container-transparent-text1">Convert</h5>
            <p className="container-transparent-text2">
              Convert between digital currencies with no registration.
            </p>
            <img
              className="container-transparent-img"
              width="65px"
              style={{
                marginTop: "-10px",
                marginLeft: "400px",
                position: "absolute",
              }}
              src="/carib-coin-logo.png"
              alt=""
            />
          </div>
        </>
      )}
    </div>
  );
};

export default SignIn;
