import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Header from "../components/signUpHeader";
import useLocalStorage from "use-local-storage";
import Loader from "../components/Loader";
import axios from "axios";
import BASE_URI from "../core";
import { toast } from "react-toastify";
import ReactPasswordChecklist from "react-password-checklist";

const SignUp = () => {
  const [credentials, setCredentials] = useState({
    email: "",
    password: "",
    agree: false,
  });
  const [validate, setValidate] = useState(false);
  const [confirmPass, setConfirmPass] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (validate) {
      let address = localStorage.getItem("atres");

      let obj = {
        ...credentials,
        name: credentials.email.split("@")[0],
        address: address !== undefined && address !== null ? address : "",
      };

      setLoading(true);
      try {
        const response = await axios.post(`${BASE_URI}signup`, obj);
        setLoading(false);

        if (
          response.data.message ===
          "An Email sent to your account please verify"
        ) {
          navigate("/email-send");
        } else if (response.data.message === "Already a User") {
          toast.error("Already a user");
        }
      } catch (error) {
        setLoading(false);
        toast.error("Please try again");
      }

      setCredentials({
        name: "",
        email: "",
        password: "",
        agree: false,
      });
      setConfirmPass("");
    } else {
      toast.warning("Please fullfill all password requirements");
    }
  };
  const gotoSignInPage = () => navigate("/");

  const [theme] = useLocalStorage("theme");

  let ico;
  if (theme == "light") {
    document.body.style = "background: #d4dde2;";
  } else if (theme == "dark") {
    document.body.style = "background: #182136;";
  }

  return (
    <div id={theme}>
      <Header />
      {loading ? (
        <Loader />
      ) : (
        <>
          <div className="signup__container justify-content-center">
            <div className="container">
              <h4 className="text-center text-white p-4">Sign up </h4>
              <h6 className="text-center" style={{ color: "#919293" }}>
                Join us with our journey!{" "}
              </h6>
              <form
                className="p-3 g-3 needs-validation"
                onSubmit={(e) => handleSubmit(e)}
              >
                <div className="col-md-12">
                  <div className="form-outline inner-addon left-addon">
                    <input
                      type="email"
                      style={{
                        height: "60px",
                        border: "2px solid darkolivegreen",
                        borderRadius: "12px",
                      }}
                      value={credentials.email}
                      onChange={(e) =>
                        setCredentials({
                          ...credentials,
                          email: e.target.value,
                        })
                      }
                      className="form-control"
                      id="validationCustom01"
                      placeholder="E-mail"
                      required
                    />
                    <label
                      htmlFor="validationCustom01"
                      className="form-label"
                    ></label>
                    <div className="valid-feedback"></div>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="form-outline inner-addon left-addon">
                    <input
                      type="password"
                      style={{
                        height: "60px",
                        border: "2px solid darkolivegreen",
                        borderRadius: "12px",
                      }}
                      className="form-control"
                      value={credentials.password}
                      onChange={(e) =>
                        setCredentials({
                          ...credentials,
                          password: e.target.value,
                        })
                      }
                      id="validationCustom01"
                      placeholder="Password"
                      required
                    />
                    <label
                      htmlFor="validationCustom01"
                      className="form-label"
                    ></label>
                    <div className="valid-feedback"></div>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="form-outline inner-addon left-addon">
                    <input
                      type="password"
                      style={{
                        height: "60px",
                        border: "2px solid darkolivegreen",
                        borderRadius: "12px",
                      }}
                      className="form-control"
                      value={confirmPass}
                      onChange={(e) => setConfirmPass(e.target.value)}
                      id="validationCustom01"
                      placeholder="Confirm Password"
                      required
                    />
                    <label
                      htmlFor="validationCustom01"
                      className="form-label"
                    ></label>
                    <div className="valid-feedback"></div>
                  </div>
                </div>
                <ReactPasswordChecklist
                  rules={[
                    "minLength",
                    "specialChar",
                    "number",
                    "capital",
                    "match",
                  ]}
                  minLength={8}
                  value={credentials.password}
                  valueAgain={confirmPass}
                  onChange={(isValid) => {
                    setValidate(isValid);
                  }}
                />
                <div className="col-md-12">
                  <label className="check-container text-white">
                    {" "}
                    I agree to the Terms & Conditions of Caribbean
                    <input
                      type="checkbox"
                      checked={credentials.agree}
                      required
                      onChange={() =>
                        setCredentials({
                          ...credentials,
                          agree: !credentials.agree,
                        })
                      }
                    />
                    <span className="checkmark"></span>
                  </label>
                </div>
                <div className="col-md-12 pt-3 pb-5">
                  <div className="form-outline inner-addon left-addon">
                    <button
                      type="submit"
                      style={{
                        height: "60px",
                        borderRadius: "20px",
                        color: "#333334",
                        fontSize: "20px",
                        fontWeight: "bold",
                        padding: "10px 40px 10px 40px",
                        border: "none",
                        backgroundColor: "#F5E494FF",
                      }}
                      className="btn btn-warning w-100 text-bold"
                    >
                      Sign Up
                    </button>
                  </div>
                </div>
                <div className="col-md-12 pt-2 pb-3">
                  <div className="form-outline inner-addon left-addon">
                    <h6
                      className="text-center form-footer-text"
                      style={{ color: "#919293" }}
                    >
                      Already have an account?{" "}
                      <span
                        className="text-success font-weight-bold"
                        onClick={gotoSignInPage}
                      >
                        Sign In
                      </span>
                    </h6>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <div className="pt-3 pb-5 mt-5 container-transparent">
            <img
              className="container-transparent-bg"
              src="/imgs/sign-up-container-bg.png"
              alt=""
            />
            <h5 className="container-transparent-text1">Convert</h5>
            <p className="container-transparent-text2">
              Convert between digital currencies with no registration.
            </p>
            <img
              className="container-transparent-img"
              width="65px"
              style={{
                marginTop: "-10px",
                marginLeft: "400px",
                position: "absolute",
              }}
              src="/carib-coin-logo.png"
              alt=""
            />
          </div>
        </>
      )}
    </div>
  );
};

export default SignUp;
