import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import MediaQuery from "react-responsive";
import useLocalStorage from "use-local-storage";
import { ConnectWalletButton } from "../utils/connectButton";
import { logout } from "../functions/inAppFunctions";

const NavBAR = ({ current_page }) => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [theme, setTheme] = useLocalStorage("dark" ? "theme" : "light");

  const SwitchTheme = () => {
    const newTheme = theme === "light" ? "dark" : "light";
    setTheme(newTheme);
  };

  let ico;
  if (theme == "light") {
    ico = <i onClick={SwitchTheme} className="fas fa-moon fa-lg"></i>;
    document.body.style = "background: #d4dde2;";
  } else if (theme == "dark") {
    ico = <i onClick={SwitchTheme} className="fas fa-sun fa-lg"></i>;
    document.body.style = "background: #182136;";
  }

  return (
    <div id={theme}>
      <MediaQuery minWidth={1224}>
        <nav
          className="navbar navbar-expand-lg navbar-light carib-head"
          style={{ backgroundColor: "#2c2b2b" }}
        >
          <div className="navbar-toggler">
            <Link
              className="navbar-brand me-2 mb-1 d-flex align-items-center"
              to="/wallet"
            >
              <img
                src="/carib-coin-logo.png"
                height="50px"
                alt="Caribbean-coin"
                loading="lazy"
                style={{ marginTop: "2px" }}
              />
              <span
                className=""
                style={{
                  fontWeight: "bold",
                  color: "#f4e081",
                  fontSize: "26px",
                }}
              >
                CARIBBEAN-COIN
              </span>
            </Link>
          </div>

          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>

          <div
            className="collapse navbar-collapse justify-content-between"
            id="navbarSupportedContent"
          >
            {/*<div className="container-fluid justify-content-between">*/}
            <ul className="navbar-nav flex-row">
              <Link
                className="navbar-brand me-2 mb-1 d-flex align-items-center"
                to="/wallet"
              >
                <img
                  src="/carib-coin-logo.png"
                  height="50px"
                  alt="Caribbean-coin"
                  loading="lazy"
                  style={{ marginTop: "2px", marginRight: "20px" }}
                />
                <span
                  className=""
                  style={{
                    fontWeight: "bold",
                    color: "#f4e081",
                    fontSize: "26px",
                  }}
                >
                  CARIBBEAN-COIN
                </span>
              </Link>
            </ul>

            <ul className="navbar-nav flex-row d-none d-md-flex">
              <li className="nav-item nav-item-link me-3 me-lg-1 p-2">
                <Link
                  className={
                    current_page === "wallet"
                      ? "nav-link d-sm-flex active align-items-sm-center"
                      : "nav-link d-sm-flex  align-items-sm-center"
                  }
                  activeClassName="active"
                  to="/wallet"
                >
                  <span className="navs d-none d-sm-block ms-1 mt-1 d-flex">
                    <i className="fas fa-wallet fa-lg mr-2"></i> Wallet
                  </span>
                </Link>
              </li>
              <li className="nav-item nav-item-link me-3 me-lg-1 p-2">
                <Link
                  className={
                    current_page === "savings"
                      ? "nav-link d-sm-flex active align-items-sm-center"
                      : "nav-link d-sm-flex  align-items-sm-center"
                  }
                  activeClassName="active"
                  to="/my-savings"
                >
                  <span className="navs d-none d-sm-block ms-1 mt-1 d-flex">
                    <i className="fas fa-sack-dollar fa-lg mr-2"></i> Savings
                  </span>
                </Link>
              </li>
              <li className="nav-item nav-item-link me-3 me-lg-1 p-2">
                <Link
                  className={
                    current_page === "invest"
                      ? "nav-link d-sm-flex active align-items-sm-center"
                      : "nav-link d-sm-flex  align-items-sm-center"
                  }
                  to="/invest"
                >
                  <span className="navs d-none d-sm-block ms-1 mt-1 d-flex">
                    <i className="fas fa-chart-line fa-lg mr-2"></i> Invest
                  </span>
                </Link>
              </li>
              <li className="nav-item nav-item-link me-3 me-lg-1 p-2">
                <Link
                  className={
                    current_page === "settings"
                      ? "nav-link d-sm-flex active align-items-sm-center"
                      : "nav-link d-sm-flex  align-items-sm-center"
                  }
                  to="/settings"
                >
                  <span className="navs d-none d-sm-block ms-1 mt-1 d-flex">
                    <i className="fas fa-gear fa-lg mr-2"></i> Settings
                  </span>
                </Link>
              </li>
            </ul>

            <ul className="navbar-nav flex-row">
              {/*<li className="nav-item me-3 me-lg-1">*/}
              {/*    <div*/}
              {/*        className="nav-link d-sm-flex align-items-sm-center header-right-btns1"*/}
              {/*    >*/}
              {/*        <h6 className="mr-1 d-sm-block theme-ico"><i className="fas fa-sun fa-lg"></i></h6>*/}
              {/*        <label className="switch">*/}
              {/*            <input type="checkbox" onChange={() => setTheme(!theme)} checked={theme ? "true" : ""}/>*/}
              {/*            <span className="slider round"></span>*/}
              {/*        </label>*/}
              {/*        <h6 className="ml-1 d-sm-block theme-ico"><i className="fas fa-moon fa-lg"></i></h6>*/}
              {/*    </div>*/}
              {/*</li>*/}
              <li className="nav-item me-3 me-lg-1">
                <div className="nav-link d-sm-flex align-items-sm-center header-right-btns1">
                  <h6 className="d-none d-sm-block ms-1 mt-1 d-flex">{ico}</h6>
                </div>
              </li>
              <li className="nav-item me-3 me-lg-1">
                <ConnectWalletButton page={"walletPage"} />
              </li>
              <li
                className="nav-item"
                onClick={() => {
                  setOpen(!open);
                }}
              >
                <a className="nav-link align-items-sm-center header-right-btns3">
                  <img
                    src="/imgs/user.jpg"
                    height="40px"
                    style={{ borderRadius: "20px" }}
                  />
                </a>
                <div
                  className={`dropdown-menuu ${open ? "active" : "inactive"}`}
                >
                  <li>
                    <a className="dropdown-item">
                      <div className="user-drop-info">
                        <div className="d-flex">
                          <div className="flex-shrink-0 me-3">
                            <div className="avatar avatar-online">
                              <img
                                src="/imgs/user.jpg"
                                alt
                                height="35px"
                                style={{ borderRadius: "20px" }}
                                className="w-px-40 h-auto rounded-circle"
                              />
                            </div>
                          </div>
                          <div className="flex-grow-1">
                            <span className="fw-bolder d-block ml-2">
                              {localStorage.getItem("email")}
                            </span>
                            <br />
                          </div>
                        </div>
                        <div className="user-acc-info row mt-3">
                          <div className="col-1">
                            <i className="fas fa-exclamation-circle fa-lg"></i>
                          </div>
                          <div className="col-10">
                            <p className="user-acc-info pb-2">
                              Verify your identity to access more of <br /> what
                              Caribbean has to offer
                            </p>
                          </div>
                        </div>
                        <div className="float-right">
                          <span className="user-drop-link1 ">Hide</span>
                          <span className="user-drop-link2 ml-2">
                            Verify ID
                          </span>
                        </div>
                      </div>
                    </a>
                  </li>
                  <li>
                    <Link
                      className="dropdown-item mb-2 text-white justify-content-center"
                      to="/my-profile"
                    >
                      <i className="fas fa-user mr-2"></i>
                      <span className="">Profile</span>
                    </Link>
                    <a
                      onClick={() => logout(navigate)}
                      className="dropdown-item text-white justify-content-center"
                    >
                      <i className="fas fa-right-from-bracket mr-2"></i>
                      <span className="">Log Out</span>
                    </a>
                  </li>
                </div>
              </li>
            </ul>
            {/*</div>*/}
          </div>
        </nav>
      </MediaQuery>
    </div>
  );
};

export default NavBAR;
