import React, {useEffect} from 'react';
import {Link, useNavigate} from "react-router-dom";
function Dashboard() {

    const navigate = useNavigate();

    useEffect(() => {
        setTimeout(() => {
            navigate('/sign-in', {replace: true});
        }, 1000);
    }, []);
    return(
        <>
        </>
    )
}
export default Dashboard;
