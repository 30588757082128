import logo from "./logo.svg";
import "./App.css";
import React, { useState, useEffect } from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import "../src/style.css";
import useLocalStorage from "use-local-storage";
import "@rainbow-me/rainbowkit/styles.css";
import {
  lightTheme,
  RainbowKitProvider,
  darkTheme,
} from "@rainbow-me/rainbowkit";
import { ledgerWallet } from "@rainbow-me/rainbowkit/wallets";

import { configureChains, createConfig, WagmiConfig } from "wagmi";
import { bscTestnet, bsc } from "wagmi/chains";
import { alchemyProvider } from "wagmi/providers/alchemy";
import { publicProvider } from "wagmi/providers/public";
import { infuraProvider } from "wagmi/providers/infura";
import { connectorsForWallets } from "@rainbow-me/rainbowkit";
import {
  walletConnectWallet,
  metaMaskWallet,
  coinbaseWallet,
  rainbowWallet,
} from "@rainbow-me/rainbowkit/wallets";
import {
  AddCurrency,
  Auth,
  Convert,
  EmailSend,
  EmailVerify,
  ForgotPass,
  Invest,
  Login,
  MySavings,
  NewPass,
  PageNotFound,
  Privacy,
  Profile,
  Send,
  Settings,
  Signup,
  Topup,
  Verification,
  VerificationID,
  VerificationSelfie,
  VerificationStatus,
  Wallet,
} from "./views";
import axios from "axios";
import BASE_URI from "./core";
import NavBAR from "./components/Navbar";
import Toast from "./components/Toast";

const { chains, publicClient } = configureChains(
  [bsc],
  [
    alchemyProvider({ apiKey: process.env.REACT_APP_ALCHEMY_API_KEY }),
    publicProvider(),
    infuraProvider({ apiKey: "e7a0e98d6ca44d4aba3402a05672fa49" }),
  ]
);
const projectId = "f30cc960a8a1704f13b2b889a94dab34";
const connectors = connectorsForWallets([
  {
    groupName: "Recommended",
    wallets: [
      walletConnectWallet({ chains, projectId }),
      metaMaskWallet({ chains, projectId }),
      coinbaseWallet({ chains, projectId }),
      rainbowWallet({ chains, projectId }),
    ],
  },
  {
    groupName: "Other",
    wallets: [ledgerWallet({ chains, projectId })],
  },
]);

const wagmiConfig = createConfig({
  autoConnect: true,
  connectors,
  publicClient,
});

const Disclaimer = (props) => (
  <props.Text>
    By connecting your wallet, you agree to the{" "}
    <props.Link href="www.youtube.com">Terms of Service</props.Link> and
    acknowledge you have read and understand the protocol{" "}
    <props.Link href="www.youtube.com">Disclaimer</props.Link>
  </props.Text>
);

function App() {
  const [theme] = useLocalStorage("theme", "light");
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const logout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("pass");
    localStorage.removeItem("email");
    setIsLoggedIn(false);
  };

  useEffect(() => {
    let token = localStorage.getItem("token");

    if (token !== undefined && token !== null && token !== "") {
      const checkUser = async () => {
        try {
          const response = await axios.get(`${BASE_URI}checkUser/${token}`);
          if (response.data.message === "Valid User") {
            setIsLoggedIn(true);
          }
        } catch (error) {
          logout();
        }
      };
      checkUser();
    } else {
      logout();
    }
  }, []);

  return (
    <WagmiConfig config={wagmiConfig}>
      <RainbowKitProvider
        appInfo={{
          appName: "Pweb3",
          disclaimer: Disclaimer,
          learnMoreUrl: "",
        }}
        chains={chains}
        theme={{
          lightMode: lightTheme({
            accentColor: "#1bc74f",
            borderradius: "medium",
            overlayBlur: "small",
          }),
          darkMode: darkTheme({
            accentColor: "#1bc74f",
            borderradius: "medium",
            overlayBlur: "small",
          }),
        }}
      >
        <Toast />
        <BrowserRouter>
          <Routes>
            <Route
              path="/wallet"
              element={isLoggedIn ? <Wallet /> : <Navigate to="/" replace />}
            />
            <Route
              path="/my-savings"
              element={isLoggedIn ? <MySavings /> : <Navigate to="/" replace />}
            />
            <Route
              path="/top-up"
              element={isLoggedIn ? <Topup /> : <Navigate to="/" replace />}
            />
            <Route
              path="/send"
              element={isLoggedIn ? <Send /> : <Navigate to="/" replace />}
            />
            <Route
              path="/convert"
              element={isLoggedIn ? <Convert /> : <Navigate to="/" replace />}
            />
            <Route
              path="/add-currency"
              element={
                isLoggedIn ? <AddCurrency /> : <Navigate to="/" replace />
              }
            />
            <Route
              path="/settings"
              element={isLoggedIn ? <Settings /> : <Navigate to="/" replace />}
            />
            <Route
              path="/my-profile"
              element={isLoggedIn ? <Profile /> : <Navigate to="/" replace />}
            />
            <Route
              path="/invest"
              element={isLoggedIn ? <Invest /> : <Navigate to="/" replace />}
            />
            <Route
              path="/verification/status"
              element={
                isLoggedIn ? (
                  <VerificationStatus />
                ) : (
                  <Navigate to="/" replace />
                )
              }
            />
            <Route
              path="/verification"
              element={
                isLoggedIn ? <Verification /> : <Navigate to="/" replace />
              }
            />
            <Route
              path="/verification/id"
              element={
                isLoggedIn ? <VerificationID /> : <Navigate to="/" replace />
              }
            />
            <Route
              path="/verification/selfie"
              element={
                isLoggedIn ? (
                  <VerificationSelfie />
                ) : (
                  <Navigate to="/" replace />
                )
              }
            />
            <Route
              path="/my-profile/2fa"
              element={isLoggedIn ? <Auth /> : <Navigate to="/" replace />}
            />
            <Route
              path="/my-profile/privacy-settings"
              element={isLoggedIn ? <Privacy /> : <Navigate to="/" replace />}
            />
            <Route path="/sign-up" element={<Signup />} />
            <Route
              path="/"
              element={
                <Login isLoggedIn={isLoggedIn} setIsLoggedIn={setIsLoggedIn} />
              }
            />
            <Route path="/forgot-password" element={<ForgotPass />} />
            <Route path="/password-reset/:id/:token" element={<NewPass />} />
            <Route path="/users/:id/verify/:token" element={<EmailVerify />} />
            <Route path="/email-send" element={<EmailSend />} />
            <Route path="*" element={<PageNotFound />} />
          </Routes>
        </BrowserRouter>
      </RainbowKitProvider>
    </WagmiConfig>
  );
}

export default App;
