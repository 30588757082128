import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Header from "../components/AccountHeader";
import MediaQuery from "react-responsive";
import useLocalStorage from "use-local-storage";
import NavBAR from "../components/Navbar";
import { logout } from "../functions/inAppFunctions";
import Footer from "../components/Footer";
const PrivacyPage = () => {
  const navigate = useNavigate();
  const gotoSignUpPage = () => navigate("/sign-up");
  const [open, setOpen] = useState(false);
  const [openMobile, setOpenMobile] = useState(false);

  const LogOut = () => navigate("/");

  const [theme, setTheme] = useLocalStorage("dark" ? "theme" : "light");

  const SwitchTheme = () => {
    const newTheme = theme === "light" ? "dark" : "light";
    setTheme(newTheme);
  };

  let ico;
  if (theme == "light") {
    ico = (
      <i onClick={SwitchTheme} className="fas fa-moon fa-lg text-white"></i>
    );
    document.body.style = "background: #d4dde2;";
  } else if (theme == "dark") {
    ico = <i onClick={SwitchTheme} className="fas fa-sun fa-lg text-white"></i>;
    document.body.style = "background: #182136;";
  }

  return (
    <div id={theme}>
      <MediaQuery minWidth={1224}>
        <NavBAR current_page={"settings"} />{" "}
        <div className="mb-3 mt-4 row w-100">
          <div className="col-3">
            <ul className="navbar-nav flex-row">
              <li className="nav-item me-3 me-lg-1">
                <Link
                  className="nav-link d-sm-flex align-items-sm-center body-header-btns1"
                  to="/"
                >
                  <h6 className="d-none d-sm-block body-header-btns33-in ms-1 mt-1 d-flex">
                    <i className="fas fa-arrow-left mr-2"></i>{" "}
                  </h6>
                  Back
                </Link>
              </li>
            </ul>
          </div>
          <div className="col-4 container">
            <h4 className="text-center text-white pt-4 pb-3">
              Privacy Settings{" "}
            </h4>
            <div className="col-12 mt-4 mb-4">
              <div className="collapse show" id="collapseExample">
                <div
                  className="mt-2 navbar-brand p-1 w-100 me-2 mb-1 align-items-center"
                  style={{ borderRadius: "15px" }}
                >
                  <span
                    style={{
                      height: "50px",
                      width: "60px",
                      boxShadow: "1px 1px 5px 3px #888888",
                      borderRadius: "10px",
                      color: "#333334FF",
                      padding: "6px 10px 0px 10px",
                      fontSize: "25px",
                      fontWeight: "bold",
                      border: "none",
                      backgroundColor: "white",
                    }}
                    className="float-left btn mr-3 btn-warning text-bold"
                  >
                    <i className="fas fa-user"></i>
                  </span>
                  <span
                    className="float-left"
                    style={{
                      fontWeight: "bold",
                      color: "white",
                      fontSize: "15px",
                    }}
                  >
                    <span
                      style={{
                        fontWeight: "bold",
                        color: "white",
                        fontSize: "15px",
                      }}
                    >
                      Allow search by nickname
                    </span>
                    <br />
                    <span
                      style={{
                        fontWeight: "bold",
                        color: "#bbbbbb",
                        fontSize: "12px",
                      }}
                    >
                      Let people find you by @testuser
                    </span>
                  </span>
                  <span
                    className="float-right"
                    style={{
                      color: "#2EC6BA",
                      fontSize: "14px",
                      fontWeight: "bold",
                      marginTop: "3%",
                    }}
                  >
                    <label className="switch">
                      <input type="checkbox" />
                      <span className="slider round"></span>
                    </label>
                  </span>
                </div>
                <div
                  className="mt-2 navbar-brand p-1 w-100 me-2 mb-1 align-items-center"
                  style={{ borderRadius: "15px" }}
                >
                  <span
                    style={{
                      height: "50px",
                      width: "60px",
                      boxShadow: "1px 1px 5px 3px #888888",
                      borderRadius: "10px",
                      color: "#333334FF",
                      padding: "6px 10px 0px 10px",
                      fontSize: "25px",
                      fontWeight: "bold",
                      border: "none",
                      backgroundColor: "white",
                    }}
                    className="float-left btn mr-3 btn-warning text-bold"
                  >
                    <i className="fas fa-envelope"></i>
                  </span>
                  <span
                    className="float-left"
                    style={{
                      fontWeight: "bold",
                      color: "white",
                      fontSize: "15px",
                    }}
                  >
                    <span
                      style={{
                        fontWeight: "bold",
                        color: "white",
                        fontSize: "15px",
                      }}
                    >
                      Allow search by email
                    </span>
                    <br />
                    <span
                      style={{
                        fontWeight: "bold",
                        color: "#bbbbbb",
                        fontSize: "12px",
                      }}
                    >
                      Let people find you by
                    </span>
                    <br />
                    <span
                      style={{
                        fontWeight: "bold",
                        color: "#bbbbbb",
                        fontSize: "12px",
                      }}
                    >
                      testuser@gmail.com
                    </span>
                  </span>
                  <span
                    className="float-right"
                    style={{
                      color: "#2EC6BA",
                      fontSize: "14px",
                      fontWeight: "bold",
                      marginTop: "3%",
                    }}
                  >
                    <label className="switch">
                      <input type="checkbox" />
                      <span className="slider round"></span>
                    </label>
                  </span>
                </div>
                <div
                  className="mt-2 navbar-brand p-1 w-100 me-2 mb-1 align-items-center"
                  style={{ borderRadius: "15px" }}
                >
                  <span
                    style={{
                      height: "50px",
                      width: "60px",
                      boxShadow: "1px 1px 5px 3px #888888",
                      borderRadius: "10px",
                      color: "#333334FF",
                      padding: "6px 10px 0px 10px",
                      fontSize: "25px",
                      fontWeight: "bold",
                      border: "none",
                      backgroundColor: "white",
                    }}
                    className="float-left btn mr-3 btn-warning text-bold"
                  >
                    <i className="fas fa-mobile"></i>
                  </span>
                  <span
                    className="float-left"
                    style={{
                      fontWeight: "bold",
                      color: "white",
                      fontSize: "15px",
                    }}
                  >
                    <span
                      style={{
                        fontWeight: "bold",
                        color: "white",
                        fontSize: "15px",
                      }}
                    >
                      Allow search by phone number
                    </span>
                    <br />
                    <span
                      style={{
                        fontWeight: "bold",
                        color: "#bbbbbb",
                        fontSize: "12px",
                      }}
                    >
                      Let people find you by +1234567890123
                    </span>
                  </span>
                  <span
                    className="float-right"
                    style={{
                      color: "#2EC6BA",
                      fontSize: "14px",
                      fontWeight: "bold",
                      marginTop: "3%",
                    }}
                  >
                    <label className="switch">
                      <input type="checkbox" />
                      <span className="slider round"></span>
                    </label>
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="col-3 align-center">
            <ul className="navbar-nav flex-row">
              <li className="nav-item me-3 me-lg-1">
                <a
                  className="nav-link d-sm-flex align-items-sm-center body-header-btns1"
                  href="#"
                >
                  <h6 className="d-none d-sm-block ms-1 mt-1 d-flex">
                    <i className="fas fa-commenting fa-lg mr-2"></i> Need Help?
                  </h6>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </MediaQuery>

      <MediaQuery maxWidth={1224}>
        <div
          className="navbar-toggler d-flex justify-content-between align-items-center carib-head"
          style={{ position: "fixed", zIndex: "3", top: "0", width: "100%" }}
        >
          <Link
            className="nav-link d-sm-flex align-items-sm-center body-header-btns33"
            to="/"
          >
            <h6 className="d-none d-sm-block body-header-btns33-in ms-1 mt-1 d-flex">
              <i className="fas fa-arrow-left mr-2"></i>{" "}
            </h6>
          </Link>
          <div className="navbar-brand ">
            <span
              className=""
              style={{ fontWeight: "bold", color: "white", fontSize: "16px" }}
            >
              Privacy Settings
            </span>
          </div>
          <div className="navbar-brand">
            <span
              style={{
                height: "40px",
                borderRadius: "100%",
                padding: "5px 3px 0px 3px",
                fontSize: "20px",
                border: "none",
              }}
              className="w-100 text-bold carib-text-color"
            >
              {ico}
            </span>
            <span
              className="text-white"
              onClick={() => {
                setOpenMobile(!open);
              }}
            >
              <i className="fas fa-ellipsis-h ml-3 fa-lg text-white"></i>
            </span>
          </div>
        </div>
        <div className={`mobile-menu ${openMobile ? "active" : "inactive"}`}>
          <div className="navbar-toggler d-flex pt-4">
            <Link
              className="navbar-brand me-2 mb-1 d-flex align-items-center"
              to="/wallet"
            >
              <img
                src="/carib-coin-logo.png"
                height="40px"
                alt="Caribbean-coin"
                loading="lazy"
                style={{ marginTop: "2px" }}
              />
              <span
                className="ml-2"
                style={{
                  fontWeight: "bold",
                  color: "#f4e081",
                  fontSize: "24px",
                }}
              >
                CARIBBEAN-COIN
              </span>
            </Link>
            <i
              className="fas fa-times fa-lg"
              onClick={() => {
                setOpenMobile(open);
              }}
              style={{ margin: "27px 0px 0px 0px", color: "#f4e081" }}
            ></i>
          </div>
          <ul className="navbar-nav p-3 text-white">
            <li className="">
              <span className="align-items-sm-center">
                <h6 className="ml-2 d-flex">{localStorage.getItem("email")}</h6>
                <span className="verify-btn ml-2">
                  <i className="fas fa-exclamation-circle mr-1"></i> Not Verify
                </span>
              </span>
            </li>
          </ul>
          <ul className="navbar-nav p-4 text-white">
            <li className="menu-nav-item">
              <Link
                className="nav-link d-sm-flex align-items-sm-center"
                to="/my-profile"
              >
                <h6 className="d-none d-sm-block ms-1 mt-1 d-flex">
                  <i className="fas fa-user fa-lg mt-2 mr-3"></i> My Profile
                </h6>
              </Link>
            </li>
            <li className="menu-nav-item">
              <Link
                className="nav-link d-sm-flex align-items-sm-center"
                to="/invest"
              >
                <h6 className="d-none d-sm-block ms-1 mt-1 d-flex">
                  <i
                    className="fas fa-exchange fa-lg mt-2 mr-3"
                    style={{ rotate: "90deg" }}
                  ></i>{" "}
                  Swap
                </h6>
              </Link>
            </li>
            <li className="menu-nav-item">
              <Link className="nav-link d-sm-flex align-items-sm-center">
                <h6 className="d-none d-sm-block ms-1 mt-1 d-flex">
                  <i className="fas fa-commenting fa-lg mt-2 mr-3"></i> Need
                  help
                </h6>
              </Link>
            </li>
            <li className="menu-nav-item">
              <a
                className="nav-link d-sm-flex align-items-sm-center"
                onClick={() => logout(navigate)}
              >
                <h6 className="d-none d-sm-block ms-1 mt-1 d-flex">
                  <i className="fas fa-sign-out fa-lg mt-2 mr-3"></i> Sign out
                </h6>
              </a>
            </li>
          </ul>
        </div>
        <div className="signup__container w-100 pt-5">
          <div
            className="container-mob w-100 pb-4"
            style={{ zIndex: "0px", width: "100%", height: "850px" }}
          >
            <div className="col-12 mt-4 pt-5 mb-4">
              <div className="collapse show" id="collapseExample">
                <div
                  className="mt-2 navbar-brand p-1 w-100 me-2 mb-1 align-items-center"
                  style={{ borderRadius: "15px" }}
                >
                  <span
                    style={{
                      height: "50px",
                      width: "60px",
                      boxShadow: "1px 1px 5px 3px #888888",
                      borderRadius: "10px",
                      color: "#333334FF",
                      padding: "6px 10px 0px 10px",
                      fontSize: "25px",
                      fontWeight: "bold",
                      border: "none",
                      backgroundColor: "white",
                    }}
                    className="float-left btn mr-3 btn-warning text-bold"
                  >
                    <i className="fas fa-user"></i>
                  </span>
                  <span
                    className="float-left"
                    style={{
                      fontWeight: "bold",
                      color: "white",
                      fontSize: "15px",
                    }}
                  >
                    <span
                      style={{
                        fontWeight: "bold",
                        color: "white",
                        fontSize: "15px",
                      }}
                    >
                      Allow search by nickname
                    </span>
                    <br />
                    <span
                      style={{
                        fontWeight: "bold",
                        color: "#bbbbbb",
                        fontSize: "12px",
                      }}
                    >
                      Let people find you by @testuser
                    </span>
                  </span>
                  <span
                    className="float-right"
                    style={{
                      color: "#2EC6BA",
                      fontSize: "14px",
                      fontWeight: "bold",
                      marginTop: "3%",
                    }}
                  >
                    <label className="switch">
                      <input type="checkbox" />
                      <span className="slider round"></span>
                    </label>
                  </span>
                </div>
                <div
                  className="mt-2 navbar-brand p-1 w-100 me-2 mb-1 align-items-center"
                  style={{ borderRadius: "15px" }}
                >
                  <span
                    style={{
                      height: "50px",
                      width: "60px",
                      boxShadow: "1px 1px 5px 3px #888888",
                      borderRadius: "10px",
                      color: "#333334FF",
                      padding: "6px 10px 0px 10px",
                      fontSize: "25px",
                      fontWeight: "bold",
                      border: "none",
                      backgroundColor: "white",
                    }}
                    className="float-left btn mr-3 btn-warning text-bold"
                  >
                    <i className="fas fa-envelope"></i>
                  </span>
                  <span
                    className="float-left"
                    style={{
                      fontWeight: "bold",
                      color: "white",
                      fontSize: "15px",
                    }}
                  >
                    <span
                      style={{
                        fontWeight: "bold",
                        color: "white",
                        fontSize: "15px",
                      }}
                    >
                      Allow search by email
                    </span>
                    <br />
                    <span
                      style={{
                        fontWeight: "bold",
                        color: "#bbbbbb",
                        fontSize: "12px",
                      }}
                    >
                      Let people find you by
                    </span>
                    <br />
                    <span
                      style={{
                        fontWeight: "bold",
                        color: "#bbbbbb",
                        fontSize: "12px",
                      }}
                    >
                      testuser@gmail.com
                    </span>
                  </span>
                  <span
                    className="float-right"
                    style={{
                      color: "#2EC6BA",
                      fontSize: "14px",
                      fontWeight: "bold",
                      marginTop: "3%",
                    }}
                  >
                    <label className="switch">
                      <input type="checkbox" />
                      <span className="slider round"></span>
                    </label>
                  </span>
                </div>
                <div
                  className="mt-2 navbar-brand p-1 w-100 me-2 mb-1 align-items-center"
                  style={{ borderRadius: "15px" }}
                >
                  <span
                    style={{
                      height: "50px",
                      width: "60px",
                      boxShadow: "1px 1px 5px 3px #888888",
                      borderRadius: "10px",
                      color: "#333334FF",
                      padding: "6px 10px 0px 10px",
                      fontSize: "25px",
                      fontWeight: "bold",
                      border: "none",
                      backgroundColor: "white",
                    }}
                    className="float-left btn mr-3 btn-warning text-bold"
                  >
                    <i className="fas fa-mobile"></i>
                  </span>
                  <span
                    className="float-left"
                    style={{
                      fontWeight: "bold",
                      color: "white",
                      fontSize: "15px",
                    }}
                  >
                    <span
                      style={{
                        fontWeight: "bold",
                        color: "white",
                        fontSize: "15px",
                      }}
                    >
                      Allow search by phone number
                    </span>
                    <br />
                    <span
                      style={{
                        fontWeight: "bold",
                        color: "#bbbbbb",
                        fontSize: "12px",
                      }}
                    >
                      Let people find you by +1234567890123
                    </span>
                  </span>
                  <span
                    className="float-right"
                    style={{
                      color: "#2EC6BA",
                      fontSize: "14px",
                      fontWeight: "bold",
                      marginTop: "3%",
                    }}
                  >
                    <label className="switch">
                      <input type="checkbox" />
                      <span className="slider round"></span>
                    </label>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer currentPage={"wallet"} />
      </MediaQuery>
    </div>
  );
};

export default PrivacyPage;
