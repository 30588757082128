import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Header from "../components/AccountHeader";
import MediaQuery from "react-responsive";
import useLocalStorage from "use-local-storage";
import { logout } from "../functions/inAppFunctions";
import Footer from "../components/Footer";

const SendView = () => {
  const navigate = useNavigate();
  const gotoSignUpPage = () => navigate("/sign-up");
  const [open, setOpen] = useState(false);
  const [openMobile, setOpenMobile] = useState(false);
  const LogOut = () => navigate("/");

  const [theme, setTheme] = useLocalStorage("dark" ? "theme" : "light");

  const SwitchTheme = () => {
    const newTheme = theme === "light" ? "dark" : "light";
    setTheme(newTheme);
  };

  let ico;
  if (theme == "light") {
    ico = (
      <i onClick={SwitchTheme} className="fas fa-moon fa-lg text-white"></i>
    );
    document.body.style = "background: #d4dde2;";
  } else if (theme == "dark") {
    ico = <i onClick={SwitchTheme} className="fas fa-sun fa-lg text-white"></i>;
    document.body.style = "background: #182136;";
  }

  return (
    <div id={theme}>
      <MediaQuery minWidth={1224}>
        <nav
          className="navbar navbar-expand-lg navbar-light carib-head"
          style={{ backgroundColor: "#2c2b2b" }}
        >
          <div className="navbar-toggler">
            <Link
              className="navbar-brand me-2 mb-1 d-flex align-items-center"
              to="/wallet"
            >
              <img
                src="/carib-coin-logo.png"
                height="50px"
                alt="Caribbean-coin"
                loading="lazy"
                style={{ marginTop: "2px" }}
              />
              <span
                className=""
                style={{
                  fontWeight: "bold",
                  color: "#f4e081",
                  fontSize: "26px",
                }}
              >
                CARIBBEAN-COIN
              </span>
            </Link>
          </div>

          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>

          <div
            className="collapse navbar-collapse justify-content-between"
            id="navbarSupportedContent"
          >
            {/*<div className="container-fluid justify-content-between">*/}
            <ul className="navbar-nav flex-row">
              <Link
                className="navbar-brand me-2 mb-1 d-flex align-items-center"
                to="/wallet"
              >
                <img
                  src="/carib-coin-logo.png"
                  height="50px"
                  alt="Caribbean-coin"
                  loading="lazy"
                  style={{ marginTop: "2px", marginRight: "20px" }}
                />
                <span
                  className=""
                  style={{
                    fontWeight: "bold",
                    color: "#f4e081",
                    fontSize: "26px",
                  }}
                >
                  CARIBBEAN-COIN
                </span>
              </Link>
            </ul>

            <ul className="navbar-nav flex-row d-none d-md-flex">
              <li className="nav-item nav-item-link me-3 me-lg-1 p-2">
                <Link
                  className="nav-link d-sm-flex active align-items-sm-center"
                  activeClassName="active"
                  to="/wallet"
                >
                  <span className="navs d-none d-sm-block ms-1 mt-1 d-flex">
                    <i className="fas fa-wallet fa-lg mr-2"></i> Wallet
                  </span>
                </Link>
              </li>
              <li className="nav-item nav-item-link me-3 me-lg-1 p-2">
                <Link
                  className="nav-link d-sm-flex  align-items-sm-center"
                  activeClassName="active"
                  to="/my-savings"
                >
                  <span className="navs d-none d-sm-block ms-1 mt-1 d-flex">
                    <i className="fas fa-sack-dollar fa-lg mr-2"></i> Savings
                  </span>
                </Link>
              </li>
              <li className="nav-item nav-item-link me-3 me-lg-1 p-2">
                <Link
                  className="nav-link d-sm-flex align-items-sm-center"
                  to="/invest"
                >
                  <span className="navs d-none d-sm-block ms-1 mt-1 d-flex">
                    <i className="fas fa-chart-line fa-lg mr-2"></i> Invest
                  </span>
                </Link>
              </li>
              <li className="nav-item nav-item-link me-3 me-lg-1 p-2">
                <Link
                  className="nav-link d-sm-flex align-items-sm-center"
                  to="/settings"
                >
                  <span className="navs d-none d-sm-block ms-1 mt-1 d-flex">
                    <i className="fas fa-gear fa-lg mr-2"></i> Settings
                  </span>
                </Link>
              </li>
            </ul>

            <ul className="navbar-nav flex-row">
              <li className="nav-item me-3 me-lg-1">
                <div className="nav-link d-sm-flex align-items-sm-center header-right-btns1">
                  <h6 className="d-none d-sm-block ms-1 mt-1 d-flex">{ico}</h6>
                </div>
              </li>
              <li className="nav-item me-3 me-lg-1">
                <a
                  className="nav-link d-sm-flex align-items-sm-center header-right-btns2"
                  href="#"
                >
                  <h6 className="d-none d-sm-block ms-1 mt-1 d-flex">
                    <i className="fas fa-wallet fa-lg mr-2"></i> Connect Wallet
                  </h6>
                </a>
              </li>
              <li
                className="nav-item"
                onClick={() => {
                  setOpen(!open);
                }}
              >
                <a className="nav-link align-items-sm-center header-right-btns3">
                  <img
                    src="/imgs/user.jpg"
                    height="40px"
                    style={{ borderRadius: "20px" }}
                  />
                </a>
                <div
                  className={`dropdown-menuu ${open ? "active" : "inactive"}`}
                >
                  <li>
                    <a className="dropdown-item">
                      <div className="user-drop-info">
                        <div className="d-flex">
                          <div className="flex-shrink-0 me-3">
                            <div className="avatar avatar-online">
                              <img
                                src="/imgs/user.jpg"
                                alt
                                height="35px"
                                style={{ borderRadius: "20px" }}
                                className="w-px-40 h-auto rounded-circle"
                              />
                            </div>
                          </div>
                          <div className="flex-grow-1">
                            <span className="fw-bolder d-block ml-2">
                              {localStorage.getItem("email")}
                            </span>
                            <br />
                          </div>
                        </div>
                        <div className="user-acc-info row mt-3">
                          <div className="col-1">
                            <i className="fas fa-exclamation-circle fa-lg"></i>
                          </div>
                          <div className="col-10">
                            <p className="user-acc-info pb-2">
                              Verify your identity to access more of <br /> what
                              Caribbean has to offer
                            </p>
                          </div>
                        </div>
                        <div className="float-right">
                          <span className="user-drop-link1 ">Hide</span>
                          <span className="user-drop-link2 ml-2">
                            Verify ID
                          </span>
                        </div>
                      </div>
                    </a>
                  </li>
                  <li>
                    <Link
                      className="dropdown-item mb-2 text-white justify-content-center"
                      to="/my-profile"
                    >
                      <i className="fas fa-user mr-2"></i>
                      <span className="">Profile</span>
                    </Link>
                    <Link
                      onClick={() => logout(navigate)}
                      className="dropdown-item text-white justify-content-center"
                      to="/"
                    >
                      <i className="fas fa-right-from-bracket mr-2"></i>
                      <span className="">Log Out</span>
                    </Link>
                  </li>
                </div>
              </li>
            </ul>
            {/*</div>*/}
          </div>
        </nav>
        <div className="mb-3 mt-4 row w-100">
          <div className="col-3">
            <ul className="navbar-nav flex-row">
              <li className="nav-item me-3 me-lg-1">
                <Link
                  className="nav-link d-sm-flex align-items-sm-center body-header-btns1"
                  to="/"
                >
                  <h6 className="d-none d-sm-block body-header-btns33-in ms-1 mt-1 d-flex">
                    <i className="fas fa-arrow-left mr-2"></i>{" "}
                  </h6>
                  Back
                </Link>
              </li>
            </ul>
          </div>
          <div className="col-4 container pb-4">
            <h4 className="text-center text-white p-4">Send </h4>
            <h6 className="text-left ml-3" style={{ color: "#bbbbbb" }}>
              From Account{" "}
            </h6>
            <div className="col-md-12 pt-3 pb-3">
              <Link
                className="bg-white navbar-brand p-1 w-100 me-2 mb-1 d-flex align-items-center"
                style={{ borderRadius: "15px" }}
              >
                <span className="fi fi-aw p-3 ml-2 mr-3" height="200px"></span>
                <span
                  className=""
                  style={{
                    fontWeight: "bold",
                    color: "#333334FF",
                    fontSize: "15px",
                  }}
                >
                  <span
                    className="carib-text-color"
                    style={{ fontWeight: "bold", fontSize: "18px" }}
                  >
                    DBSD
                  </span>
                  <br />
                  <span
                    style={{
                      fontWeight: "bold",
                      color: "#2EC6BA",
                      fontSize: "14px",
                    }}
                  >
                    <small>D€</small> 10,000.00
                  </span>
                </span>
                <span
                  className="amount-right1"
                  style={{ color: "#333334FF", fontSize: "24px" }}
                >
                  <i className="fas fa-arrow-down"></i>
                </span>
              </Link>
            </div>
            <h6 className="text-left ml-3" style={{ color: "#bbbbbb" }}>
              To Account{" "}
            </h6>
            <div className="col-md-12 pt-3 pb-3">
              <input
                type="text"
                placeholder="Type email/phone number"
                className="bg-white p-3 font-italic w-100 me-2 mb-1 d-flex align-items-center"
                style={{ borderRadius: "15px", color: "#333334FF" }}
              />
            </div>
            <div className="col-md-12 pt-3 pb-3">
              <button className="text-center w-100 align-items-center select-contact-btn">
                <span className="mb-3">
                  <i className="fas fa-address-book"></i> select contact
                </span>
              </button>
            </div>
            <hr
              style={{ color: "white", width: "90%", backgroundColor: "white" }}
            />
            <h6 className="text-center ml-3" style={{ color: "#fff" }}>
              How much do you want to send?{" "}
            </h6>
            <div className="col-md-12 pt-3 pb-3">
              <div
                className="bg-white p-4 w-100  mb-1 align-items-center"
                style={{ borderRadius: "15px" }}
              >
                <span style={{ color: "#333334FF", fontSize: "18px" }}>
                  Amount
                </span>
                <br />
                <input
                  type="text"
                  placeholder="D€ 1000.00"
                  className="bg-white send-i-amount p-3 w-100 me-2 mb-1 d-flex align-items-center"
                  style={{
                    borderBottom: "1px solid #2EC6BA",
                    color: "#2EC6BA",
                    border: "none",
                  }}
                />
                <span
                  style={{
                    fontWeight: "bold",
                    color: "#939394",
                    fontSize: "10px",
                  }}
                >
                  Transaction fee is D€ 10.00
                </span>
                <br />
              </div>
            </div>
            <div className="col-md-12 pt-3 pb-3">
              <button className="text-center w-100 align-items-center send-btn">
                <span className="mb-3">Send</span>
              </button>
            </div>
          </div>
          <div className="col-3 align-center">
            <ul className="navbar-nav flex-row">
              <li className="nav-item me-3 me-lg-1">
                <a
                  className="nav-link d-sm-flex align-items-sm-center body-header-btns1"
                  href="#"
                >
                  <h6 className="d-none d-sm-block ms-1 mt-1 d-flex">
                    <i className="fas fa-commenting fa-lg mr-2"></i> Need Help?
                  </h6>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </MediaQuery>

      <MediaQuery maxWidth={1224}>
        <div
          className="navbar-toggler d-flex justify-content-between align-items-center carib-head"
          style={{ position: "fixed", zIndex: "3", top: "0", width: "100%" }}
        >
          <Link
            className="nav-link d-sm-flex align-items-sm-center body-header-btns33"
            to="/"
          >
            <h6 className="d-none d-sm-block body-header-btns33-in ms-1 mt-1 d-flex">
              <i className="fas fa-arrow-left mr-2"></i>{" "}
            </h6>
          </Link>
          <div className="navbar-brand ">
            <span
              className=""
              style={{ fontWeight: "bold", color: "white", fontSize: "16px" }}
            >
              Send
            </span>
          </div>
          <div className="navbar-brand">
            <span
              style={{
                height: "40px",
                borderRadius: "100%",
                padding: "5px 3px 0px 3px",
                fontSize: "20px",
                border: "none",
              }}
              className="w-100 text-bold carib-text-color"
            >
              {ico}
            </span>
            <span
              className="text-white"
              onClick={() => {
                setOpenMobile(!open);
              }}
            >
              <i className="fas fa-ellipsis-h ml-3 fa-lg text-white"></i>
            </span>
          </div>
        </div>
        <div className={`mobile-menu ${openMobile ? "active" : "inactive"}`}>
          <div className="navbar-toggler d-flex pt-4">
            <Link
              className="navbar-brand me-2 mb-1 d-flex align-items-center"
              to="/wallet"
            >
              <img
                src="/carib-coin-logo.png"
                height="40px"
                alt="Caribbean-coin"
                loading="lazy"
                style={{ marginTop: "2px" }}
              />
              <span
                className="ml-2"
                style={{
                  fontWeight: "bold",
                  color: "#f4e081",
                  fontSize: "24px",
                }}
              >
                CARIBBEAN-COIN
              </span>
            </Link>
            <i
              className="fas fa-times fa-lg"
              onClick={() => {
                setOpenMobile(open);
              }}
              style={{ margin: "27px 0px 0px 0px", color: "#f4e081" }}
            ></i>
          </div>
          <ul className="navbar-nav p-3 text-white">
            <li className="">
              <span className="align-items-sm-center">
                <h6 className="ml-2 d-flex">{localStorage.getItem("email")}</h6>
                <span className="verify-btn ml-2">
                  <i className="fas fa-exclamation-circle mr-1"></i> Not Verify
                </span>
              </span>
            </li>
          </ul>
          <ul className="navbar-nav p-4 text-white">
            <li className="menu-nav-item">
              <Link
                className="nav-link d-sm-flex align-items-sm-center"
                to="/my-profile"
              >
                <h6 className="d-none d-sm-block ms-1 mt-1 d-flex">
                  <i className="fas fa-user fa-lg mt-2 mr-3"></i> My Profile
                </h6>
              </Link>
            </li>
            <li className="menu-nav-item">
              <Link
                className="nav-link d-sm-flex align-items-sm-center"
                to="/invest"
              >
                <h6 className="d-none d-sm-block ms-1 mt-1 d-flex">
                  <i
                    className="fas fa-exchange fa-lg mt-2 mr-3"
                    style={{ rotate: "90deg" }}
                  ></i>{" "}
                  Swap
                </h6>
              </Link>
            </li>
            <li className="menu-nav-item">
              <Link className="nav-link d-sm-flex align-items-sm-center">
                <h6 className="d-none d-sm-block ms-1 mt-1 d-flex">
                  <i className="fas fa-commenting fa-lg mt-2 mr-3"></i> Need
                  help
                </h6>
              </Link>
            </li>
            <li className="menu-nav-item">
              <a
                className="nav-link d-sm-flex align-items-sm-center"
                onClick={() => logout(navigate)}
              >
                <h6 className="d-none d-sm-block ms-1 mt-1 d-flex">
                  <i className="fas fa-sign-out fa-lg mt-2 mr-3"></i> Sign out
                </h6>
              </a>
            </li>
          </ul>
        </div>
        <div className="signup__container w-100 pt-5">
          <div
            className="container-mob w-100 pb-4"
            style={{ zIndex: "0px", width: "100%", height: "800px" }}
          >
            <h6 className="text-left pt-4 ml-3" style={{ color: "#c0c0c0" }}>
              From Account{" "}
            </h6>
            <div className="col-md-12 pt-2 pb-3">
              <Link
                className="bg-white navbar-brand p-1 w-100 me-2 mb-1 align-items-center"
                style={{ borderRadius: "15px" }}
              >
                <div className="row">
                  <div className="col-2">
                    <span
                      className="fi fi-aw p-3 ml-2 mr-3"
                      height="200px"
                    ></span>
                  </div>
                  <div className="col-8">
                    <p
                      style={{
                        fontWeight: "bold",
                        color: "#333334FF",
                        fontSize: "18px",
                      }}
                    >
                      DBSD
                    </p>
                    <p
                      style={{
                        fontWeight: "bold",
                        marginTop: "-19px",
                        marginBottom: "0px",
                        color: "#2EC6BA",
                        fontSize: "14px",
                      }}
                    >
                      <small>D€</small> 10,000.00
                    </p>
                  </div>
                  <div className="col-2">
                    <span
                      className=""
                      style={{ color: "#333334FF", fontSize: "24px" }}
                    >
                      <i className="fas fa-arrow-down"></i>
                    </span>
                  </div>
                </div>
              </Link>
            </div>
            <h6 className="text-left ml-3" style={{ color: "#919293" }}>
              To Account{" "}
            </h6>
            <div className="col-md-12 pt-3 pb-3">
              <input
                type="text"
                placeholder="Type email/phone number"
                className="bg-white p-3 font-italic w-100 me-2 mb-1 d-flex align-items-center"
                style={{ borderRadius: "15px", color: "#333334FF" }}
              />
            </div>
            <div className="col-md-12 pt-3 pb-3">
              <button className="text-center w-100 align-items-center select-contact-btn">
                <span className="mb-3">
                  <i className="fas fa-address-book"></i> select contact
                </span>
              </button>
            </div>
            <hr
              style={{ color: "white", width: "90%", backgroundColor: "white" }}
            />
            <h6 className="text-center ml-3" style={{ color: "#fff" }}>
              How much do you want to send?{" "}
            </h6>
            <div className="col-md-12 pt-3 pb-3">
              <div
                className="bg-white p-4 w-100  mb-1 align-items-center"
                style={{ borderRadius: "15px" }}
              >
                <span style={{ color: "#333334FF", fontSize: "18px" }}>
                  Amount
                </span>
                <br />
                <input
                  type="text"
                  placeholder="D€ 1000.00"
                  className="bg-white send-i-amount p-3 w-100 me-2 mb-1 d-flex align-items-center"
                  style={{
                    borderBottom: "1px solid #2EC6BA",
                    color: "#2EC6BA",
                    border: "none",
                  }}
                />
                <span
                  style={{
                    fontWeight: "bold",
                    color: "#939394",
                    fontSize: "10px",
                  }}
                >
                  Transaction fee is D€ 10.00
                </span>
                <br />
              </div>
            </div>
            <div className="col-md-12 pt-3 pb-3">
              <button className="text-center w-100 align-items-center send-btn">
                <span className="mb-3">Send</span>
              </button>
            </div>
          </div>
        </div>
        <Footer currentPage={"wallet"} />
      </MediaQuery>
    </div>
  );
};

export default SendView;
