import React from "react";
import { ConnectButton } from "@rainbow-me/rainbowkit";

export const ConnectWalletButton = ({ page }) => {
  let count = 0;
  const RenderButton = () => {
    switch (page) {
      case "sighup":
        return (
          <a
            className="nav-link d-sm-flex align-items-sm-center header-btns"
            href="#"
          >
            <h6 className="d-none d-sm-block ms-1 mt-1 d-flex">
              <i className="fas fa-wallet fa-lg mr-2"></i> Connect Wallet
            </h6>
          </a>
        );

      default:
        return (
          <a
            className="nav-link d-sm-flex align-items-sm-center header-right-btns2"
            href="#"
          >
            <h6 className="d-none d-sm-block ms-1 mt-1 d-flex">
              <i className="fas fa-wallet fa-lg mr-2"></i> Connect Wallet
            </h6>
          </a>
        );
    }
  };
  return (
    <ConnectButton.Custom>
      {({
        account,
        chain,
        openAccountModal,
        openConnectModal,
        openChainModal,
        authenticationStatus,
        mounted,
      }) => {
        if (account !== undefined) {
          if (count === 0) {
            localStorage.setItem("atres", account.address);
            count++;
          }
        } else {
          if (count === 0) {
            localStorage.removeItem("atres");
            count++;
          }
        }
        // Note: If your app doesn't use authentication, you
        // can remove all 'authenticationStatus' checks

        const ready = mounted && authenticationStatus !== "loading";
        const connected =
          ready &&
          account &&
          chain &&
          (!authenticationStatus || authenticationStatus === "authenticated");

        return (
          <div
            {...(!ready && {
              "aria-hidden": true,
              style: {
                opacity: 0,
                pointerEvents: "none",
                userSelect: "none",
              },
            })}
          >
            {(() => {
              if (!connected) {
                return (
                  <li
                    onClick={openConnectModal}
                    type="button"
                    className="Conect"
                  >
                    {/* <BiWalletAlt size={"25"} /> */}
                    <RenderButton />
                  </li>
                );
              }

              if (chain.unsupported) {
                return (
                  <li onClick={openChainModal}>
                    <a
                      className="nav-link d-sm-flex align-items-sm-center header-right-btns2"
                      href="#"
                    >
                      {" "}
                      <h6 className="d-none d-sm-block ms-1 mt-1 d-flex">
                        Wrong Network
                      </h6>
                    </a>
                  </li>
                );
              } else {
                return (
                  <>
                    <li onClick={openAccountModal}>
                      <a
                        className="nav-link d-sm-flex align-items-sm-center header-right-btns2"
                        href="#"
                      >
                        {chain.hasIcon && (
                          <div
                            style={{
                              background: chain.iconBackground,
                              width: 25,
                              height: 25,
                              borderRadius: 999,
                              overflow: "hidden",
                              marginRight: 11,
                              marginBottom: 4,
                            }}
                          >
                            {chain.iconUrl && (
                              <img
                                alt={chain.name ?? "Chain icon"}
                                src={chain.iconUrl}
                                style={{ width: 25, height: 25 }}
                              />
                            )}
                          </div>
                        )}
                        <h6 className="d-none d-sm-block ms-1 mt-1 d-flex">
                          {account.displayName}
                          {account.displayBalance
                            ? ` (${account.displayBalance})`
                            : ""}
                        </h6>
                      </a>
                    </li>
                  </>
                );
              }
            })()}
          </div>
        );
      }}
    </ConnectButton.Custom>
  );
};
