import React, { useState } from "react";
import styles from "./modal.module.css";
import axios from "axios";
import { toast } from "react-toastify";
import ReactPasswordChecklist from "react-password-checklist";
import BASE_URI from "../../core";
import { AiOutlineClose } from "react-icons/ai";

const Modal = ({ setOpenModal, height, marginTop, setLoading }) => {
  const [validate, setValidate] = useState(false);
  const [credentials, setCredentials] = useState({
    current: "",
    new: "",
    confirm: "",
  });
  const token = localStorage.getItem("token");
  const pass = localStorage.getItem("pass");

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (validate) {
      if (pass === credentials.current) {
        setLoading(true);

        try {
          const response = await axios.put(`${BASE_URI}edituser/${token}`, {
            password: credentials.new,
          });

          if (response.data.message === "success") {
            localStorage.setItem("pass", credentials.new);
            toast.success("password Updated Successfully");
            setOpenModal(false);
            setLoading(false);
          }
        } catch (error) {
          setLoading(false);
          toast.error("Internal Server Error");
        }
      } else {
        toast.warning("Invalid Current Password");
      }
    } else {
      toast.warning("Please fullfill all password requirements");
    }
  };

  return (
    <div className={styles.container} style={{ height }}>
      <form
        className={styles.form_container}
        onSubmit={handleSubmit}
        style={{ marginTop }}
      >
        <AiOutlineClose
          className={styles.closeIcon}
          onClick={() => setOpenModal(false)}
        />
        <h3>Edit Password</h3>
        <input
          type="password"
          placeholder="Current Password"
          name="password"
          value={credentials.current}
          onChange={(e) =>
            setCredentials({ ...credentials, current: e.target.value })
          }
          required
          className={styles.input}
        />
        <input
          type="password"
          placeholder="New Password"
          name="password"
          value={credentials.new}
          onChange={(e) =>
            setCredentials({ ...credentials, new: e.target.value })
          }
          required
          className={styles.input}
        />
        <input
          type="password"
          placeholder="Confirm New Password"
          name="password"
          value={credentials.confirm}
          onChange={(e) =>
            setCredentials({ ...credentials, confirm: e.target.value })
          }
          required
          className={styles.input}
        />
        <ReactPasswordChecklist
          className={styles.passValidator}
          rules={["minLength", "specialChar", "number", "capital", "match"]}
          minLength={8}
          value={credentials.new}
          valueAgain={credentials.confirm}
          onChange={(isValid) => {
            setValidate(isValid);
          }}
        />
        <button type="submit" className={styles.btn}>
          Save
        </button>
      </form>
    </div>
  );
};

export default Modal;
