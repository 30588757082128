import React from "react";
import style from "./email.module.css";
import sent from "../../assets/email-sent.png";

const EmailSend = () => {
  const resetPass = localStorage.getItem("reset");
  localStorage.removeItem("reset");
  return (
    <div className={style.mainDiv}>
      <img src={sent} alt="email-sent" />
      {resetPass === "pass" ? (
        <h1>We have sent password reset link to your email..</h1>
      ) : (
        <h1>
          We have sent verification link to your email..
          <br />
          Please verify email firstly..
        </h1>
      )}
    </div>
  );
};

export default EmailSend;
