import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Header from "../components/AccountHeader";
import MediaQuery from "react-responsive";
import useLocalStorage from "use-local-storage";
import NavBAR from "../components/Navbar";
import { ReactSortable } from "react-sortablejs";
import { logout } from "../functions/inAppFunctions";
import Footer from "../components/Footer";

const WalletView = () => {
  const navigate = useNavigate();
  const gotoSignUpPage = () => navigate("/sign-up");
  const [open, setOpen] = useState(false);
  const [openMobile, setOpenMobile] = useState(false);
  const LogOut = () => navigate("/");

  const [theme, setTheme] = useLocalStorage("dark" ? "theme" : "light");

  const SwitchTheme = () => {
    const newTheme = theme === "light" ? "dark" : "light";
    setTheme(newTheme);
  };

  let ico;
  if (theme == "light") {
    ico = (
      <i onClick={SwitchTheme} className="fas fa-moon fa-lg text-white"></i>
    );
    document.body.style = "background: #d4dde2;";
  } else if (theme == "dark") {
    ico = <i onClick={SwitchTheme} className="fas fa-sun fa-lg text-white"></i>;
    document.body.style = "background: #182136;";
  }

  const [coin, setCoin] = useState([
    { id: 1, name: "DAWG", ico: "fi fi-aw p-3 ml-2 mr-2", bal: "0" },
    { id: 2, name: "DBSD", ico: "fi fi-bs p-3 ml-2 mr-2", bal: "0" },
    { id: 2, name: "DBBD", ico: "fi fi-bb p-3 ml-2 mr-2", bal: "0" },
  ]);

  const [coin2, setCoin2] = useState([
    { id: 1, name: "DBSD", ico: "fi fi-bs p-3 ml-2 mr-2", bal: "0" },
  ]);

  const [coin3, setCoin3] = useState([
    { id: 1, name: "AWG", ico: "fi fi-aw p-3 ml-2 mr-2", bal: "0" },
  ]);

  // const coinsList = document.querySelector(".coins-list");
  // const coins = document.querySelectorAll(".carib-coins");
  //
  // coins.forEach(coin => {
  //     coin.addEventListener("dragstart", () => {
  //         setTimeout(() => coin.classList.add("dragging"), 0);
  //     })
  //
  //     coin.addEventListener("dragend", () => coin.classList.remove("dragging"));
  // });
  //
  // const initCoinList = (e) => {
  //
  //     const draggingCoin = coinsList.querySelector(".dragging");
  //
  //     const siblings = [...coinsList.querySelectorAll(".carib-coins:not(.dragging)")];
  //
  //     let nextSibling = siblings.find(sibling => {
  //         return e.clientY <= sibling.offsetTop + sibling.offsetHeight / 2;
  //     });
  //
  //     coinsList.insertBefore(draggingCoin, nextSibling);
  // }
  //
  // coinsList.addEventListener("dragover", initCoinList);

  return (
    <div id={theme}>
      <MediaQuery minWidth={1224}>
        <NavBAR current_page={"wallet"} />{" "}
        <div className="mb-3 mt-4 row w-100">
          <div className="col-3">
            <ul className="navbar-nav flex-row">
              <li className="nav-item me-3 me-lg-1">
                <Link
                  className="nav-link d-sm-flex align-items-sm-center body-header-btns1"
                  to="/add-currency"
                >
                  <h6 className="d-none d-sm-block ms-1 mt-1 d-flex">
                    <i className="fas fa-plus-circle fa-lg mr-2"></i> Add New
                    Currency
                  </h6>
                </Link>
              </li>
            </ul>
          </div>
          <div className="col-4 container pb-4">
            <h4 className="text-center text-white p-4">My Wallet </h4>
            <h6 className="text-center" style={{ color: "#bbbbbb" }}>
              Total balance of My accounts and cards{" "}
            </h6>
            <div className="col-md-12 pt-3 pb-3">
              <div className="form-outline inner-addon left-addon">
                <button
                  type="submit"
                  style={{
                    height: "60px",
                    borderRadius: "20px",
                    color: "#333334",
                    fontSize: "20px",
                    fontWeight: "bold",
                    padding: "10px 40px 10px 40px",
                    border: "none",
                    backgroundColor: "#F5E494FF",
                  }}
                  className="btn btn-warning align-items-center w-100 text-bold"
                >
                  <small style={{ fontWeight: "bold" }}>D€</small> 0{" "}
                  <span className="float-right">...</span>
                </button>
              </div>
            </div>
            <div className="col-md-12 d-flex pt-3 pb-3">
              <Link
                to="/top-up"
                className="form-outline text-center ml-1 inner-addon text-white left-addon"
              >
                <span
                  style={{
                    height: "60px",
                    borderRadius: "20px",
                    padding: "15px 15px 0px 15px",
                    fontSize: "20px",
                    fontWeight: "bold",
                    border: "none",
                    backgroundColor: "white",
                  }}
                  className="wallet-btn-desk btn btn-warning w-100 text-bold"
                >
                  <i className="fas fa-circle-plus"></i>
                </span>
                <span className="h6">Top up</span>
              </Link>
              <Link
                to="/send"
                className="form-outline text-center ml-4 inner-addon text-white left-addon"
              >
                <span
                  style={{
                    height: "60px",
                    borderRadius: "20px",
                    padding: "15px 15px 0px 15px",
                    fontSize: "20px",
                    fontWeight: "bold",
                    border: "none",
                    backgroundColor: "white",
                  }}
                  className="wallet-btn-desk btn btn-warning w-100 text-bold"
                >
                  <i className="fas fa-paper-plane"></i>
                </span>
                <span className="h6">Send</span>
              </Link>
              <Link
                to="/convert"
                className="form-outline text-center ml-4 inner-addon text-white left-addon"
              >
                <span
                  style={{
                    height: "60px",
                    borderRadius: "20px",
                    padding: "15px 15px 0px 15px",
                    fontSize: "20px",
                    fontWeight: "bold",
                    border: "none",
                    backgroundColor: "white",
                  }}
                  className="wallet-btn-desk btn btn-warning w-100 text-bold"
                >
                  <i className="fas fa-money-bill-transfer"></i>
                </span>
                <span className="h6">Convert</span>
              </Link>
              <Link
                to="/invest"
                className="form-outline text-center ml-4 inner-addon text-white left-addon"
              >
                <span
                  style={{
                    height: "60px",
                    borderRadius: "20px",
                    padding: "15px 15px 0px 15px",
                    fontSize: "20px",
                    fontWeight: "bold",
                    border: "none",
                    backgroundColor: "white",
                  }}
                  className="wallet-btn-desk btn btn-warning w-100 text-bold"
                >
                  <i className="fas fa-chart-line"></i>
                </span>
                <span className="h6">Invest</span>
              </Link>
            </div>
            <hr
              style={{ color: "white", width: "90%", backgroundColor: "white" }}
            />
            <div className="col-12 mb-4">
              <p>
                <span
                  className="text-white ml-2"
                  data-toggle="collapse"
                  href="#collapseExample"
                  role="button"
                  aria-expanded="false"
                  aria-controls="collapseExample"
                >
                  Caribbean Account
                </span>
              </p>
              <div className="coins-list1 collapse show" id="collapseExample">
                <ReactSortable
                  group="coins"
                  animation={2}
                  delayOnTouchStart={true}
                  delay={2}
                  list={coin}
                  setList={setCoin}
                >
                  {coin.map((item) => (
                    <Link
                      draggable="true"
                      className="carib-coins bg-white navbar-brand p-1 w-100 me-2 mb-2 align-items-center"
                      style={{ borderRadius: "15px" }}
                      to="/wallet"
                    >
                      <span className={item.ico} height="100px"></span>
                      <span
                        className="carib-text-color"
                        style={{ fontWeight: "bold", fontSize: "20px" }}
                      >
                        {item.name}
                      </span>
                      <span
                        className="float-right"
                        style={{
                          fontWeight: "bold",
                          color: "#2EC6BA",
                          margin: "12px 20px 0px 0px",
                          fontSize: "18px",
                        }}
                      >
                        <small style={{ fontWeight: "bold" }}>D€</small>{" "}
                        {item.bal}{" "}
                      </span>
                    </Link>
                  ))}
                </ReactSortable>
              </div>
            </div>
            <div className="col-12 mb-4">
              <p>
                <span className="text-white ml-2">On-Chain : 0xa312..9c8d</span>
              </p>
              <div className="coins-list2 collapse show" id="collapseExample">
                <ReactSortable
                  group="coins"
                  animation={2}
                  delayOnTouchStart={true}
                  delay={2}
                  list={coin2}
                  setList={setCoin2}
                >
                  {coin2.map((item) => (
                    <Link
                      draggable="true"
                      className="carib-coins bg-white navbar-brand p-1 w-100 me-2 mb-1 align-items-center"
                      style={{ borderRadius: "15px" }}
                      to="/wallet"
                    >
                      <span className={item.ico} height="100px"></span>
                      <span
                        className="carib-text-color"
                        style={{ fontWeight: "bold", fontSize: "20px" }}
                      >
                        {item.name}
                      </span>
                      <span
                        className="float-right"
                        style={{
                          fontWeight: "bold",
                          color: "#2EC6BA",
                          margin: "12px 20px 0px 0px",
                          fontSize: "18px",
                        }}
                      >
                        <small style={{ fontWeight: "bold" }}>D€</small>{" "}
                        {item.bal}{" "}
                      </span>
                    </Link>
                  ))}
                </ReactSortable>
              </div>
            </div>
            <div className="col-12">
              <p>
                <span className="text-white ml-2">Traditional Account</span>
              </p>
              <div className="coins-list3 collapse show" id="collapseExample">
                <ReactSortable
                  group="coins"
                  animation={2}
                  delayOnTouchStart={true}
                  delay={2}
                  list={coin3}
                  setList={setCoin3}
                >
                  {coin3.map((item) => (
                    <Link
                      draggable="true"
                      className="carib-coins bg-white navbar-brand p-1 w-100 me-2 mb-1 align-items-center"
                      style={{ borderRadius: "15px" }}
                      to="/wallet"
                    >
                      <span className={item.ico} height="100px"></span>
                      <span
                        className="carib-text-color"
                        style={{ fontWeight: "bold", fontSize: "20px" }}
                      >
                        {item.name}
                      </span>
                      <span
                        className="float-right"
                        style={{
                          fontWeight: "bold",
                          color: "#2EC6BA",
                          margin: "12px 20px 0px 0px",
                          fontSize: "18px",
                        }}
                      >
                        <small style={{ fontWeight: "bold" }}>D€</small>{" "}
                        {item.bal}{" "}
                      </span>
                    </Link>
                  ))}
                </ReactSortable>
              </div>
            </div>
          </div>
          <div className="col-3 align-center">
            <ul className="navbar-nav flex-row">
              <li className="nav-item me-3 me-lg-1">
                <a
                  className="nav-link d-sm-flex align-items-sm-center body-header-btns1"
                  href="#"
                >
                  <h6 className="d-none d-sm-block ms-1 mt-1 d-flex">
                    <i className="fas fa-commenting fa-lg mr-2"></i> Need Help?
                  </h6>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </MediaQuery>

      <MediaQuery maxWidth={1224}>
        <div
          className="navbar-toggler d-flex justify-content-between align-items-center carib-head"
          style={{ position: "fixed", zIndex: "3", top: "0", width: "100%" }}
        >
          <Link className="navbar-brand" to="/wallet">
            <img
              src="/carib-coin-logo.png"
              height="40px"
              alt="Caribbean-coin"
              loading="lazy"
              style={{ marginTop: "2px" }}
            />
          </Link>
          <div className="navbar-brand">
            <span
              className=""
              style={{ fontWeight: "bold", color: "white", fontSize: "16px" }}
            >
              My Wallet
            </span>
          </div>
          <div className="navbar-brand">
            <span
              style={{
                height: "40px",
                borderRadius: "100%",
                padding: "5px 3px 0px 3px",
                fontSize: "20px",
                border: "none",
              }}
              className="w-100 text-bold carib-text-color"
            >
              {ico}
            </span>
            <span
              className="text-white"
              onClick={() => {
                setOpenMobile(!open);
              }}
            >
              <i className="fas fa-ellipsis-h ml-3 fa-lg text-white"></i>
            </span>
          </div>
        </div>
        <div className={`mobile-menu ${openMobile ? "active" : "inactive"}`}>
          <div className="navbar-toggler d-flex pt-4">
            <Link
              className="navbar-brand me-2 mb-1 d-flex align-items-center"
              to="/wallet"
            >
              <img
                src="/carib-coin-logo.png"
                height="40px"
                alt="Caribbean-coin"
                loading="lazy"
                style={{ marginTop: "2px" }}
              />
              <span
                className="ml-2"
                style={{
                  fontWeight: "bold",
                  color: "#f4e081",
                  fontSize: "24px",
                }}
              >
                CARIBBEAN-COIN
              </span>
            </Link>
            <i
              className="fas fa-times fa-lg"
              onClick={() => {
                setOpenMobile(open);
              }}
              style={{ margin: "27px 0px 0px 0px", color: "#f4e081" }}
            ></i>
          </div>
          <ul className="navbar-nav p-3 text-white">
            <li className="">
              <span className="align-items-sm-center">
                <h6 className="ml-2 d-flex">{localStorage.getItem("email")}</h6>
                <span className="verify-btn ml-2">
                  <i className="fas fa-exclamation-circle mr-1"></i> Not Verify
                </span>
              </span>
            </li>
          </ul>
          <ul className="navbar-nav p-4 text-white">
            <li className="menu-nav-item">
              <Link
                className="nav-link d-sm-flex align-items-sm-center"
                to="/my-profile"
              >
                <h6 className="d-none d-sm-block ms-1 mt-1 d-flex">
                  <i className="fas fa-user fa-lg mt-2 mr-3"></i> My Profile
                </h6>
              </Link>
            </li>
            <li className="menu-nav-item">
              <Link
                className="nav-link d-sm-flex align-items-sm-center"
                to="/invest"
              >
                <h6 className="d-none d-sm-block ms-1 mt-1 d-flex">
                  <i
                    className="fas fa-exchange fa-lg mt-2 mr-3"
                    style={{ rotate: "90deg" }}
                  ></i>{" "}
                  Swap
                </h6>
              </Link>
            </li>
            <li className="menu-nav-item">
              <Link className="nav-link d-sm-flex align-items-sm-center">
                <h6 className="d-none d-sm-block ms-1 mt-1 d-flex">
                  <i className="fas fa-commenting fa-lg mt-2 mr-3"></i> Need
                  help
                </h6>
              </Link>
            </li>
            <li className="menu-nav-item">
              <a
                className="nav-link d-sm-flex align-items-sm-center"
                onClick={() => logout(navigate)}
              >
                <h6 className="d-none d-sm-block ms-1 mt-1 d-flex">
                  <i className="fas fa-sign-out fa-lg mt-2 mr-3"></i> Sign out
                </h6>
              </a>
            </li>
          </ul>
        </div>
        <div className="signup__container w-100 pt-5">
          <div
            className="container-mob w-100 pb-4"
            style={{ zIndex: "0px", width: "100%", height: "800px" }}
          >
            <h6 className="text-center p-4" style={{ color: "#c0c0c0" }}>
              Total balance of My accounts and cards{" "}
            </h6>
            <div className="col-md-12 pt-3 pb-3">
              <div className="form-outline inner-addon left-addon">
                <button
                  type="submit"
                  style={{
                    height: "60px",
                    borderRadius: "20px",
                    color: "#333334",
                    fontSize: "20px",
                    fontWeight: "bold",
                    padding: "10px 40px 10px 40px",
                    border: "none",
                    backgroundColor: "#F5E494FF",
                  }}
                  className="btn btn-warning align-items-center w-100 text-bold"
                >
                  <small style={{ fontWeight: "bold" }}>D€</small> 0{" "}
                  <span className="float-right">...</span>
                </button>
              </div>
            </div>
            <div className="col-md-12 d-flex justify-content-between pt-3 pb-3">
              <Link
                to="/top-up"
                className="form-outline text-center ml-1 inner-addon text-white left-addon"
              >
                <span
                  style={{
                    height: "60px",
                    borderRadius: "20px",
                    padding: "15px 15px 0px 15px",
                    fontSize: "20px",
                    fontWeight: "bold",
                    border: "none",
                    backgroundColor: "white",
                  }}
                  className="wallet-btn-desk btn btn-warning w-100 text-bold"
                >
                  <i className="fas fa-circle-plus"></i>
                </span>
                <span className="h6">Top up</span>
              </Link>
              <Link
                to="/send"
                className="form-outline text-center ml-4 inner-addon text-white left-addon"
              >
                <span
                  style={{
                    height: "60px",
                    borderRadius: "20px",
                    padding: "15px 15px 0px 15px",
                    fontSize: "20px",
                    fontWeight: "bold",
                    border: "none",
                    backgroundColor: "white",
                  }}
                  className="wallet-btn-desk btn btn-warning w-100 text-bold"
                >
                  <i className="fas fa-paper-plane"></i>
                </span>
                <span className="h6">Send</span>
              </Link>
              <Link
                to="/convert"
                className="form-outline text-center ml-4 inner-addon text-white left-addon"
              >
                <span
                  style={{
                    height: "60px",
                    borderRadius: "20px",
                    padding: "15px 15px 0px 15px",
                    fontSize: "20px",
                    fontWeight: "bold",
                    border: "none",
                    backgroundColor: "white",
                  }}
                  className="wallet-btn-desk btn btn-warning w-100 text-bold"
                >
                  <i className="fas fa-money-bill-transfer"></i>
                </span>
                <span className="h6">Convert</span>
              </Link>
              <Link
                to="/invest"
                className="form-outline text-center ml-4 inner-addon text-white left-addon"
              >
                <span
                  style={{
                    height: "60px",
                    borderRadius: "20px",
                    padding: "15px 15px 0px 15px",
                    fontSize: "20px",
                    fontWeight: "bold",
                    border: "none",
                    backgroundColor: "white",
                  }}
                  className="wallet-btn-desk btn btn-warning w-100 text-bold"
                >
                  <i className="fas fa-chart-line"></i>
                </span>
                <span className="h6">Invest</span>
              </Link>
            </div>
            <hr
              style={{ color: "white", width: "90%", backgroundColor: "white" }}
            />
            <div className="col-12 mb-4">
              <p>
                <span
                  className="text-white ml-2"
                  data-toggle="collapse"
                  href="#collapseExample"
                  role="button"
                  aria-expanded="false"
                  aria-controls="collapseExample"
                >
                  Caribbean Account
                </span>
              </p>
              <div className="collapse show" id="collapseExample">
                <ReactSortable
                  group="coins2"
                  animation={2}
                  delayOnTouchStart={true}
                  delay={2}
                  list={coin}
                  setList={setCoin}
                >
                  {coin.map((item) => (
                    <div
                      draggable="true"
                      className="bg-white navbar-brand p-1 w-100 me-2 mb-1 align-items-center mt-2"
                      style={{ borderRadius: "15px" }}
                    >
                      <span className={item.ico} height="100px"></span>
                      <span
                        className="carib-text-color"
                        style={{ fontWeight: "bold", fontSize: "18px" }}
                      >
                        {item.name}
                      </span>
                      <span
                        className="float-right"
                        style={{
                          fontWeight: "bold",
                          color: "#2EC6BA",
                          margin: "14px 20px 0px 0px",
                          fontSize: "18px",
                        }}
                      >
                        <small style={{ fontWeight: "bold" }}>D€</small>{" "}
                        {item.bal}{" "}
                      </span>
                    </div>
                  ))}
                </ReactSortable>
              </div>
            </div>
            <div className="col-12 mt-2 mb-2">
              <Link
                className="addNewCurren navbar-brand p-3 text-center w-100 mt-3"
                to="/add-currency"
              >
                <h6 className="">
                  <i className="fas fa-plus-circle fa-lg mr-2"></i> Add New
                  Currency
                </h6>
              </Link>
            </div>
          </div>
        </div>
        <Footer currentPage={"wallet"} />
      </MediaQuery>
    </div>
  );
};

export default WalletView;
